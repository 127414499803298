import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { getTrestleToken } from "../api/TrestleApiClient";
import { EventType } from "../enums/EventType";
import { TrestleToken } from "../interfaces/TrestleToken";
import { publish } from "../utils/Events";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";

export default function RootElement(props: any) {
  const [loading, setLoading] = useState<boolean>(false);

  const getTrestleTokenAsync = async () => {
    setLoading(true);
    const token = await getTrestleToken();
    localStorage.setItem(LocalStorageKeys.trestleToken, JSON.stringify(token));

    // Add event when token is fetched
    publish({ eventName: EventType.TrestleTokenFetched });
    setLoading(false);
  };

  const refreshTrestleToken = () => {
    const trestleTokenJson = localStorage.getItem(
      LocalStorageKeys.trestleToken
    );
    if (trestleTokenJson !== null && trestleTokenJson !== "") {
      /// Check if token is expired and get a new one if it is
      const trestleToken = JSON.parse(trestleTokenJson) as TrestleToken;
      trestleToken.expires_on = new Date(trestleToken.expires_on);
      const now = new Date();

      if (now >= trestleToken.expires_on) {
        getTrestleTokenAsync();
      } else {
        publish({ eventName: EventType.TrestleTokenFetched });
      }
    } else {
      getTrestleTokenAsync();
    }
  };

  useEffect(() => refreshTrestleToken(), []);

  return loading ? (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="primary" />
    </div>
  ) : (
    props.children
  );
}
