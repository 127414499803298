import { Box, Typography, Divider } from "@mui/material";

export default function ReasonBlock(props: any) {
	return (
		<Box>
			<Typography variant='h2'>{props.reasonNumber}</Typography>
			<Box height='20px'></Box>
			<Typography variant='h2'>{props.reasonTitle}</Typography>
			<Box height='30px'></Box>
			<Typography variant='body1'>{props.reasonBody}</Typography>
			<Box height='40px'></Box>
			<Divider sx={{ width: "100px", borderColor: "white" }}></Divider>
		</Box>
	);
}
