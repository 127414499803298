import { Stack, Typography, Divider, Grid } from "@mui/material";
import RoundedContainer from "./RoundedContainer";

import FeatureItem from "./FeatureItem";

export const ListingDetailFeatures: React.FC<{ features: string[] }> = ({
  features,
}) => {
  return (
    <RoundedContainer>
      <Stack spacing="20px">
        <Typography
          fontWeight="400"
          fontSize={{ xs: "1.5rem", sm: "1rem" }}
          variant="body1"
        >
          Features
        </Typography>
        <Divider sx={{ display: { xs: "none", sm: "block" } }} />
        <Grid
          container
          columnSpacing={{ xs: "0", sm: "20px", md: "20px" }}
          rowSpacing={{ xs: "20px", sm: "10px", md: "10px" }}
          direction="row"
        >
          {features.map((feature: string, index: number) => {
            return (
              <Grid key={index} item xs={12} sm={4} md={4}>
                <FeatureItem featureText={feature} />
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </RoundedContainer>
  );
};
