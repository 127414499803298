import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Divider, Grid, Skeleton } from "@mui/material";

import { containerBorderRadius } from "../constants";

export default function LoadingListingCard() {
  return (
    <Card sx={{ width: "100%", position: "relative" }}>
      <Skeleton
        sx={{ height: 200, borderRadius: containerBorderRadius }}
        animation="wave"
        variant="rectangular"
      />
      <CardContent>
        <Skeleton
          animation="wave"
          height={40}
          width="70%"
          style={{ marginBottom: 10 }}
        />
        <Skeleton
          animation="wave"
          height={20}
          width="90%"
          style={{ marginBottom: 10 }}
        />
        <Skeleton
          animation="wave"
          height={20}
          width="40%"
          style={{ marginBottom: 20 }}
        />
        <Divider />
      </CardContent>

      <CardActions>
        <Grid container columnSpacing={"20px"} direction="row">
          <Grid item xs={6}>
            <Skeleton animation="wave" height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton animation="wave" height={40} />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
