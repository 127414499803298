import { Typography, Box, Divider, Button, Grid } from "@mui/material";
import RoundedContainer from "./RoundedContainer";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Agent } from "../interfaces/Agent";

export const AgentDetailsWithImage: React.FC<{ agent: Agent }> = ({
  agent,
}) => {
  return (
    <RoundedContainer>
      <Grid container columnSpacing="20px" rowSpacing="20px" direction="row">
        <Grid item xs={12} sm={4} md={4}>
          <img
            width="100%"
            height="auto"
            alt={agent.name}
            src={agent.imageUrl}
          />
        </Grid>
        <Grid
          sx={{ textAlign: { xs: "center", sm: "left", md: "left" } }}
          item
          xs={12}
          sm={8}
          md={8}
        >
          <Typography variant="h3">{agent.name}</Typography>
          <Box height="10px"></Box>
          <Typography>{agent.title} at Graystone Realty</Typography>
          <Box height="10px"></Box>
          <Divider />
          <Box height="10px"></Box>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>License Number: </span>
            {agent.licenseNumber}
          </Typography>
          <Box height="10px"></Box>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>Service Areas: </span>
            {agent.serviceAreas.join(", ")}
          </Typography>
          <Box height="10px"></Box>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>Specialties: </span>
            {agent.specialties.join(", ")}
          </Typography>
          <Box height="20px"></Box>
          <Grid
            container
            direction="row"
            columnSpacing="20px"
            rowSpacing="20px"
            justifyContent={{ xs: "center", sm: "start" }}
          >
            <Grid xs={12} sm={6} item>
              <Button
                fullWidth
                onClick={() => {
                  window.location.href = `mailto:${agent.email}`;
                }}
                startIcon={<EmailIcon />}
                variant="outlined"
              >
                Email
              </Button>
            </Grid>
            <Grid xs={12} sm={6} item>
              <Button
                fullWidth
                onClick={() => {
                  window.location.href = `tel://+1${agent.phoneNumber.replace(
                    /\s/g,
                    ""
                  )}`;
                }}
                startIcon={<LocalPhoneIcon />}
                variant="contained"
              >
                Call
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RoundedContainer>
  );
};
