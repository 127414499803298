export default function WalkScoreWidget(props: any) {
  const formattedAddress = props.address.replaceAll(" ", "-");
  return (
    <a
      rel="nofollow"
      href={`https://www.walkscore.com/score/${formattedAddress}?utm_source=badge&utm_medium=responsive&utm_campaign=badge`}
    >
      {/*[if lte IE 8]><img src="//pp.walk.sc/badge/walk/2436-Southwest-8th-Street-Miami--FL-33135.png" style='border-radius: 0; box-shadow: none; outline: 0; color: transparent; margin: 0 0 0 2.000000%; float: left; padding: 0; width: 30.666667%; background: none; border: 0;'alt="Walk Score of 2436 Southwest 8th Street Miami FL 33135"><![endif]*/}{" "}
      {/*[if gt IE 8]><img src="//pp.walk.sc/badge/walk/2436-Southwest-8th-Street-Miami--FL-33135.svg" style='border-radius: 0; box-shadow: none; outline: 0; color: transparent; margin: 0 0 0 2.000000%; float: left; padding: 0; width: 30.666667%; background: none; border: 0;'alt="Walk Score of 2436 Southwest 8th Street Miami FL 33135"><![endif]*/}{" "}
      {/*[if !IE]> <!-- */}
      <img
        src={`//pp.walk.sc/badge/walk/${formattedAddress}.svg`}
        style={{
          borderRadius: 0,
          boxShadow: "none",
          outline: 0,
          color: "transparent",
          padding: 0,
          height: "auto",
          width: "100%",
          background: "none",
          border: 0,
        }}
        alt={`Walk Score of ${props.address}`}
      />
      {/* <![endif]*/}
    </a>
  );
}
