import { Typography, Box, Divider, Grid } from "@mui/material";
import { Listing } from "../interfaces/Listing";
import RoundedContainer from "./RoundedContainer";
import BedIcon from "@mui/icons-material/Bed";
import BathtubIcon from "@mui/icons-material/Bathtub";
import GarageIcon from "@mui/icons-material/Garage";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

export default function ListingDetailOverview(props: any) {
  const listing = props.listing as Listing;
  return (
    <RoundedContainer>
      <Grid direction="row" container>
        <Grid xs={12} sm={6} item>
          <Typography fontSize={{ xs: "1.5rem", sm: "1rem" }} variant="body1">
            Overview
          </Typography>
        </Grid>
        <Grid xs={12} sm={6} item>
          <Typography
            textAlign={{ xs: "left", sm: "right" }}
            fontSize="1rem"
            variant="body1"
          >
            <span style={{ fontWeight: "bold" }}>MLS Number: </span>
            {listing.ListingId}
          </Typography>
        </Grid>
      </Grid>
      <Box height="20px"></Box>
      <Divider sx={{ display: { xs: "none", sm: "block" } }} />
      <Box height="20px"></Box>
      <Grid
        columnSpacing={{ xs: "0", sm: "20px", md: "20px" }}
        rowSpacing="20px"
        container
        direction="row"
      >
        <Grid xs={12} sm={2} md={2} item>
          <Typography fontWeight="bold">Type</Typography>
          <Typography sx={{ wordWrap: "anywhere" }}>
            {listing.PropertyType}
          </Typography>
        </Grid>
        <Grid xs={12} sm={2} md={2} item>
          <Typography fontWeight="bold">Bedrooms</Typography>
          <BedIcon />
          <Box sx={{ display: "inline-block" }} width="10px"></Box>
          <Typography component={"span"}>{listing.BedroomsTotal}</Typography>
        </Grid>
        <Grid xs={12} sm={2} md={2} item>
          <Typography fontWeight="bold">Bathrooms</Typography>
          <BathtubIcon />
          <Box sx={{ display: "inline-block" }} width="10px"></Box>
          <Typography component={"span"}>{listing.BathroomsFull}</Typography>
        </Grid>
        <Grid xs={12} sm={2} md={2} item>
          <Typography fontWeight="bold">Garage</Typography>
          <GarageIcon />
          <Box sx={{ display: "inline-block" }} width="10px"></Box>
          <Typography component={"span"}>{listing.CoveredSpaces}</Typography>
        </Grid>
        <Grid xs={12} sm={2} md={2} item>
          <Typography fontWeight="bold">Sqft</Typography>
          <SquareFootIcon />
          <Box sx={{ display: "inline-block" }} width="10px"></Box>
          <Typography component={"span"}>{listing.LivingArea}</Typography>
        </Grid>
        <Grid xs={12} sm={2} md={2} item>
          <Typography fontWeight="bold">Year Built</Typography>
          <CalendarMonthIcon />
          <Box sx={{ display: "inline-block" }} width="10px"></Box>
          <Typography component={"span"}>{listing.YearBuilt}</Typography>
        </Grid>
      </Grid>
    </RoundedContainer>
  );
}
