import { Skeleton } from "@mui/material";
import React, { useState } from "react";

export const ImagePlaceHolder: React.FC<{
  alt: string;
  src: string | undefined;
  style: React.CSSProperties | undefined;
  onClick?: React.MouseEventHandler<HTMLImageElement> | undefined;
  widthLoading: string | number;
  heightLoading: string | number;
  variant?: "text" | "rectangular" | "rounded" | "circular" | undefined;
}> = ({ alt, src, style, onClick, widthLoading, heightLoading, variant }) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <>
      <img
        onLoad={() => {
          setLoaded(true);
        }}
        onClick={onClick}
        style={{ display: loaded ? "block" : "none", ...style }}
        alt={alt}
        src={src}
      ></img>

      {!loaded && (
        <Skeleton
          animation="wave"
          variant={variant ?? "rectangular"}
          width={widthLoading}
          height={heightLoading}
        />
      )}
    </>
  );
};
