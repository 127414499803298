import { Box, Typography } from "@mui/material";
import React from "react";

export default function SectionTitle(props: any) {
  return (
    <>
      <Typography sx={{ textAlign: "center" }} variant="h3">
        {props.title}
      </Typography>
      <Box height="15px"></Box>
      <Typography sx={{ textAlign: "center" }} variant="body1">
        {props.subtitle}
      </Typography>
    </>
  );
}
