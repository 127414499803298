import { Typography, Divider, Stack, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { getWalkScore } from "../api/WalkScoreApiClient";
import { Listing } from "../interfaces/Listing";
import { WalkScore } from "../interfaces/WalkScore";
import BikeScoreWidget from "./BikeScoreWidget";
import RoundedContainer from "./RoundedContainer";
import TransitScoreWidget from "./TransitScoreWidget";
import WalkScoreWidget from "./WalkScoreWidget";

export const ListingDetailWalkScore: React.FC<{ listing: Listing }> = ({
  listing,
}) => {
  const [walkScore, setWalkScore] = useState<WalkScore | null>(null);
  const [error, setError] = useState<Error | null>(null);

  if (error) {
    throw error;
  }

  useEffect(() => {
    const getWalkScoreAsync = async () => {
      const addressString = `${listing.UnparsedAddress} ${listing.StateOrProvince} ${listing.PostalCode}`;
      const walkScore = await getWalkScore(
        addressString,
        listing.Latitude ?? 0,
        listing.Longitude ?? 0
      );
      setWalkScore(walkScore);
      if (walkScore?.status !== 1) {
        const error = Error("Could not fetch WalkScore data!");
        setError(error);
      }
    };
    getWalkScoreAsync();
  }, [
    listing.UnparsedAddress,
    listing.StateOrProvince,
    listing.PostalCode,
    listing.Latitude,
    listing.Longitude,
  ]);

  const address = `${listing.UnparsedAddress} ${listing.PostalCity}  ${listing.StateOrProvince} ${listing.PostalCode}`;
  return (
    <RoundedContainer>
      <Stack spacing="20px">
        <Typography
          fontWeight="400"
          fontSize={{ xs: "1.5rem", sm: "1rem" }}
          variant="body1"
        >
          Walk Score
        </Typography>
        <Divider sx={{ display: { xs: "none", sm: "block" } }} />
        <Typography fontSize={{ xs: "1.5rem" }}>
          {listing.UnparsedAddress}
        </Typography>

        {walkScore != null && walkScore.walkscore !== undefined && (
          <div style={{ display: "flex" }}>
            <WalkScoreWidget address={address} />
            <Box width="8px"></Box>
            <Stack spacing="5px">
              <Box height="10px"></Box>
              <Typography fontSize="1.3rem">
                {walkScore?.description ?? "No Data"}
              </Typography>
              <Typography>
                Updated on{" "}
                {new Date(
                  walkScore?.updated ?? new Date().toString()
                ).toLocaleDateString()}
              </Typography>
            </Stack>
          </div>
        )}

        {walkScore != null && walkScore.transit !== undefined && (
          <div style={{ display: "flex" }}>
            <TransitScoreWidget address={address} />
            <Box width="8px"></Box>
            <Stack spacing="5px">
              <Box height="10px"></Box>
              <Typography fontSize="1.3rem">Good Transit</Typography>
              <Typography>
                Updated on{" "}
                {new Date(
                  walkScore?.updated ?? new Date().toString()
                ).toLocaleDateString()}
              </Typography>
            </Stack>
          </div>
        )}

        {walkScore != null && walkScore.bike !== undefined && (
          <div style={{ display: "flex" }}>
            <BikeScoreWidget address={address} />
            <Box width="8px"></Box>
            <Stack spacing="5px">
              <Box height="10px"></Box>
              <Typography fontSize="1.3rem">
                {walkScore?.bike?.description}
              </Typography>
              <Typography>
                Updated on{" "}
                {new Date(
                  walkScore?.updated ?? new Date().toString()
                ).toLocaleDateString()}
              </Typography>
            </Stack>
          </div>
        )}
      </Stack>
    </RoundedContainer>
  );
};
