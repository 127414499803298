import { Box, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { menuPages } from "../constants";
import { ReactComponent as Logo } from "../assets/images/logoWithoutRealty.svg";
import { Page } from "../interfaces/Page";
import { IconButton } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

export default function Footer() {
  const logoWidth: number = 120;
  const logoColor = "#fff";
  return (
    <Box
      sx={{
        backgroundColor: "rgb(0, 66, 116)",
        paddingTop: "30px",
        paddingBottom: "30px",
      }}
    >
      <Container>
        <Grid
          container
          direction="column"
          alignItems="center"
          rowSpacing="20px"
        >
          <Grid item>
            <Logo style={{ width: logoWidth, fill: logoColor }} />
          </Grid>
          <Grid item>
            <Grid container columnSpacing={"10px"}>
              {menuPages.map((page: Page) => {
                return (
                  <Grid
                    key={page.id}
                    sx={{ textAlign: "center" }}
                    item
                    xs={12}
                    md={3}
                  >
                    <Link
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        textDecoration: "none",
                        color: "#fff",
                        padding: "20px",
                        display: "inline-block",
                      }}
                      to={page.path}
                    >
                      {page.name.toUpperCase()}
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              sx={{ padding: "20px" }}
              aria-label="share"
              onClick={(event: React.MouseEvent) => {
                console.log("clicked!");
              }}
            >
              <InstagramIcon sx={{ color: "#fff" }}></InstagramIcon>
            </IconButton>
            <IconButton
              sx={{ padding: "20px" }}
              aria-label="share"
              onClick={(event: React.MouseEvent) => {
                console.log("clicked!");
              }}
            >
              <TwitterIcon sx={{ color: "#fff" }}></TwitterIcon>
            </IconButton>
          </Grid>
          <Grid item>
            <Typography
              sx={{ color: "#fff", textAlign: "center" }}
              variant="body1"
            >
              Copyright &copy; {new Date().getFullYear()}
              <br />
              Graystone Realty FL
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
