import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { containerBorderRadius } from "../constants";
import {
  emailJsServiceId,
  emailJsContactFormTemplateId,
  emailsJsPublicKey,
} from "../credentials/EmailJsCredentials";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { captchaSiteKey } from "../credentials/GoogleRecaptchaCredentials";
import { getCaptchaScore } from "../api/GoogleCaptchaApiClient";
import PrivacyPolicyPDF from "../assets/PrivacyPolicy.pdf";

export default function InquiryForm(props: any) {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchesXs = useMediaQuery(theme.breakpoints.up("xs"));
  const form = useRef<HTMLFormElement | null>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);

  // States
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  // Fields
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [checked, setChecked] = useState<boolean | undefined>();

  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSuccess(false);
    setError(false);
    setLoading(false);

    // Check if the captcha is checked before proceeding
    const token = captchaRef?.current?.getValue();
    captchaRef?.current?.reset();

    if (token === null || token === "" || token === undefined) {
      setChecked(false);
      return;
    }

    setLoading(true);

    /// Make an API call to google captcha service to determine if the user is human
    const isHuman = await getCaptchaScore(token);
    if (!isHuman) {
      setSuccess(false);
      setLoading(false);
      setError(true);
      return;
    }

    await emailjs
      .sendForm(
        emailJsServiceId,
        emailJsContactFormTemplateId,
        form.current!,
        emailsJsPublicKey
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current?.reset();
          setError(false);
          setSuccess(true);
          setLoading(false);
          setChecked(undefined);
        },
        (error) => {
          console.error(error.text);
          setSuccess(false);
          setLoading(false);
          setError(true);
        }
      );
  };

  return (
    <form
      onSubmit={sendEmail}
      ref={form}
      style={{
        backgroundColor: "#ffffff",
        padding: props.padding ?? "20px",
        borderRadius: containerBorderRadius,
        boxShadow: props.boxShadow
          ? "0px 20px 40px 0px rgba(0, 0, 0, 0.1)"
          : "none",
        ...props.style,
      }}
    >
      <Typography color="#000" variant="h2">
        {props.title}
      </Typography>
      <Box height="10px"></Box>
      <Typography color="#000" variant="body1">
        {props.body}
      </Typography>
      <Box height="20px"></Box>
      <FormControl sx={{ width: "100%" }}>
        <Grid container rowSpacing={"20px"} columnSpacing={"20px"}>
          <Grid item xs={12}>
            <TextField
              error={name === ""}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setName(event.target.value)}
              helperText={name === "" ? "Please type in your name" : ""}
              required
              fullWidth
              id="from_name"
              label="Name"
              variant="outlined"
              name="from_name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={email === ""}
              onChange={(event) => setEmail(event.target.value)}
              helperText={email === "" ? "Please type in your email" : ""}
              required
              type="email"
              fullWidth
              id="reply_to"
              label="Email"
              variant="outlined"
              name="reply_to"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={message === ""}
              onChange={(event) => setMessage(event.target.value)}
              helperText={message === "" ? "Please type in your message" : ""}
              required
              fullWidth
              id="message"
              name="message"
              label="Your Message"
              variant="outlined"
              multiline={true}
              rows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography color="#000">
              By submitting this form, I agree to the{" "}
              <a href={PrivacyPolicyPDF} rel="noreferrer" target="_blank">
                Terms of Use
              </a>
              .
            </Typography>
          </Grid>
          {loading && (
            <Grid item xs={12}>
              <Alert icon={<CircularProgress size={20} />} severity="info">
                Sending your message...
              </Alert>
            </Grid>
          )}
          {success && (
            <Grid item xs={12}>
              <Alert severity="success">Success! Your message was sent.</Alert>
            </Grid>
          )}
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">
                Your message was not sent. Please try again.
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <div
              style={{
                transform: matchesMd || matchesXs ? "scale(0.75)" : "none",
                transformOrigin: "0 0",
              }}
            >
              <ReCAPTCHA ref={captchaRef} sitekey={captchaSiteKey} />
            </div>
          </Grid>
          {checked === false && (
            <Grid item xs={12}>
              <Typography color="error">Please check the box above.</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              color="secondary"
              fullWidth
              size="large"
              variant="contained"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </form>
  );
}
