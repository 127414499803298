import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { Page } from "../interfaces/Page";
import { useState } from "react";
import { Divider, Typography } from "@mui/material";

export function GraystoneDrawer(props: any) {
  const [open, setOpen] = useState<boolean>(false);
  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={props.onMenuItemTap}
      onKeyDown={props.onMenuItemKeyDown}
    >
      <List>
        {(props.pages as Page[]).map((page: Page) => (
          <Link
            style={{
              textDecoration: "none",
            }}
            to={page.path}
            key={page.id}
          >
            <ListItem disablePadding>
              <ListItemButton sx={{ padding: "25px" }}>
                {page.mobileIcon}
                <Box width="8px"></Box>
                <ListItemText
                  sx={{ color: "primary.main" }}
                  primary={
                    <Typography fontSize={"12px"} fontWeight={"600"}>
                      {page.name.toUpperCase()}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <Divider
              sx={{
                color: "divider",
              }}
            />
          </Link>
        ))}
      </List>
    </Box>
  );
  return (
    <>
      <IconButton
        sx={{
          padding: "20px",
          display: { md: "none" },
        }}
        edge="start"
        color="inherit"
        aria-label="Open Drawer"
        onClick={() => setOpen(true)}
      >
        <MenuIcon />
      </IconButton>

      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(false)}
      >
        {list()}
      </SwipeableDrawer>
    </>
  );
}
