import { Box, Stack, Typography } from "@mui/material";
import RoundedContainer from "./RoundedContainer";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

export default function ContactInfoBox() {
  return (
    <RoundedContainer padding="30px">
      <Typography sx={{ fontSize: "1.5rem !important" }} variant="h3">
        Contact Information
      </Typography>
      <Box height="10px"></Box>
      <Typography fontWeight="bold" variant="body1">
        Alan Mashraghi
      </Typography>
      <Typography variant="body1">
        Broker Manager At Graystone Realty LLC
      </Typography>
      <Box height="10px"></Box>
      <Stack spacing={"10px"}>
        <div>
          <EmailIcon sx={{ marginRight: "8px" }} />
          <Typography component={"a"} href="mailto:alan@graystonerealtyfl.com">
            alan@graystonerealtyfl.com
          </Typography>
        </div>
        <div>
          <LocalPhoneIcon sx={{ marginRight: "8px" }} />
          <Typography component={"a"} href="tel://+15613510909">
            (561) 351 0909
          </Typography>
        </div>
      </Stack>
    </RoundedContainer>
  );
}
