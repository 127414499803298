import { ResponsiveAppBar } from "../components/ResponsiveAppBar";
import { useParams } from "react-router-dom";
import { agents, sectionBackgroundColor, sectionSpacing } from "../constants";
import { Agent } from "../interfaces/Agent";
import PageNotFound from "./PageNotFound";
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import ForumIcon from "@mui/icons-material/Forum";
import RoundedContainer from "../components/RoundedContainer";
import { AgentDetailsWithImage } from "../components/AgentDetailsWithImage";
import TableRowWithBottomDivider from "../components/TableRowWithBottomDivider";
import Footer from "../components/Footer";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ErrorBoundary from "../components/ErrorBoundary";
import { useEffect } from "react";

export default function AgentDetail() {
  const { id: agentId } = useParams();
  const agent: Agent | undefined = agents.find(
    (agent: Agent) => agent.id === agentId
  );

  useEffect(() => {
    document.title = agent?.name ?? "Agent";
  }, []);

  if (agent === undefined) {
    return <PageNotFound />;
  }

  return (
    <ErrorBoundary>
      <Box sx={{ backgroundColor: sectionBackgroundColor }}>
        <ResponsiveAppBar position={{ xs: "static" }} />
        <Container>
          <Box height="45px"></Box>
          <AgentDetailsWithImage agent={agent} />
          <Box height="45px"></Box>
          <Grid
            container
            columnSpacing="20px"
            rowSpacing="20px"
            direction="row"
          >
            <Grid xs={12} md={8} item>
              <RoundedContainer>
                <Typography variant="h3">About {agent.name}</Typography>
                <Box height="20px"></Box>
                <Typography>{agent.biography}</Typography>
                <Box height="20px"></Box>
                <Typography>
                  <ForumIcon sx={{ marginRight: "10px" }} />
                  <span style={{ fontWeight: "bold" }}>Language(s):</span>{" "}
                  {agent.languages.join(", ")}
                </Typography>
              </RoundedContainer>
            </Grid>
            <Grid xs={12} md={4} item>
              <RoundedContainer>
                <Typography variant="h3">Contact</Typography>
                <Box height="20px"></Box>
                <TableRowWithBottomDivider
                  title="Mobile"
                  value={
                    <a
                      style={{ textDecoration: "none" }}
                      href={`tel://+1${agent.phoneNumber.replace(/\s/g, "")}`}
                    >
                      {agent.phoneNumber}
                    </a>
                  }
                />
                <TableRowWithBottomDivider
                  title="Fax"
                  value={agent.faxNumber}
                />
                <TableRowWithBottomDivider
                  title="Email"
                  value={
                    <a
                      style={{ textDecoration: "none" }}
                      href={`mailto:${agent.email}`}
                    >
                      {agent.email}
                    </a>
                  }
                />
                <Typography textAlign="center">
                  Find {agent.name} on:
                </Typography>
                <Box height="8px"></Box>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    {agent.instagramUrl != null && (
                      <IconButton
                        aria-label="share"
                        onClick={(event: React.MouseEvent) => {
                          console.log("clicked!");
                        }}
                      >
                        <InstagramIcon
                          sx={{ color: "primary.main" }}
                        ></InstagramIcon>
                      </IconButton>
                    )}
                    {agent.twitterUrl != null && (
                      <IconButton
                        aria-label="share"
                        onClick={(event: React.MouseEvent) => {
                          console.log("clicked!");
                        }}
                      >
                        <TwitterIcon
                          sx={{ color: "rgb(0, 153, 212)" }}
                        ></TwitterIcon>
                      </IconButton>
                    )}
                    {agent.linkedinUrl != null && (
                      <IconButton
                        aria-label="share"
                        onClick={(event: React.MouseEvent) => {
                          console.log("clicked!");
                        }}
                      >
                        <LinkedInIcon
                          sx={{ color: "rgb(25, 118, 210);" }}
                        ></LinkedInIcon>
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </RoundedContainer>
            </Grid>
          </Grid>
          <Box height={sectionSpacing}></Box>
        </Container>
        <Footer />
      </Box>
    </ErrorBoundary>
  );
}
