import MapIcon from "@mui/icons-material/Map";
import IosShareIcon from "@mui/icons-material/IosShare";
import { Button, Grid, SxProps, Theme } from "@mui/material";
import { Listing } from "../interfaces/Listing";
import { handleSharing } from "../utils/WebShareHelper";
import StreetviewIcon from "@mui/icons-material/Streetview";

export const ListingActionIconButtons: React.FC<{
  listing: Listing;
  sx?: SxProps<Theme> | undefined;
}> = ({ listing, sx }) => {
  return (
    <Grid sx={sx} container direction="row" columnSpacing="8px">
      <Grid item>
        <Button
          onClick={() => {
            window.open(
              `https://maps.google.com/?q=${listing.Latitude ?? 0},${
                listing.Longitude ?? 0
              }`,
              "_blank"
            );
          }}
          sx={{ minWidth: "30px" }}
          variant="outlined"
        >
          <MapIcon />
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => {
            window.open(
              `http://maps.google.com/maps?q=&layer=c&cbll=${
                listing.Latitude ?? 0
              },${listing.Longitude ?? 0}`,
              "_blank"
            );
          }}
          sx={{ minWidth: "30px" }}
          variant="outlined"
        >
          <StreetviewIcon />
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => {
            handleSharing(
              listing.UnparsedAddress ?? "None",
              listing.PublicRemarks ?? "None"
            );
          }}
          sx={{ minWidth: "30px" }}
          variant="outlined"
        >
          <IosShareIcon />
        </Button>
      </Grid>
    </Grid>
  );
};
