import { Box, Container, Grid } from "@mui/material";
import { sectionSpacing, testimonials } from "../constants";
import SectionTitle from "./SectionTitle";
import TestimonialCard from "./TestimonialCard";
import { Fade } from "@mui/material";
import { Testimonial } from "../interfaces/Testimonial";
import useVisibility from "../hooks/useVisibility";

export default function Testimonials() {
  var transitionDelay = 0;
  const [isVisible, currentElement] = useVisibility<HTMLDivElement>();
  return (
    <Container>
      <Box sx={{ height: sectionSpacing }}></Box>
      <SectionTitle
        title="Testimonials"
        subtitle="People love working with us, and you will, too! See some of our testimonials below."
      />
      <Box sx={{ height: "30px" }}></Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        columnSpacing="35px"
        rowSpacing="40px"
        ref={currentElement}
      >
        {testimonials.map((testimonial: Testimonial, index: number) => {
          transitionDelay += index === 0 ? 0 : 200;
          return (
            <Grid key={index} item xs={12} md={4}>
              <AnimatedTestimonialCard
                active={isVisible}
                testimonial={testimonial}
                transitionDelay={`${transitionDelay}ms`}
              />
            </Grid>
          );
        })}
      </Grid>
      <Box sx={{ height: sectionSpacing }}></Box>
    </Container>
  );
}

// Builds an agent card that fades in with a transition delay if
// specified
function AnimatedTestimonialCard(props: any) {
  const testimonial = props.testimonial as Testimonial;
  return (
    <Fade
      in={props.active}
      timeout={800}
      style={{ transitionDelay: props.transitionDelay }}
    >
      <div>
        <TestimonialCard testimonial={testimonial} />
      </div>
    </Fade>
  );
}
