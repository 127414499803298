import { Box } from "@mui/material";
import React from "react";
import { containerBorderRadius } from "../constants";

export default function RoundedContainer(props: any) {
  return (
    <Box
      position={props.position ?? "static"}
      sx={{
        backgroundColor: props.backgroundColor ?? "#fff",
        borderRadius: containerBorderRadius,
        padding: props.padding ?? "40px",
        width: props.width ?? "auto",
      }}
    >
      {props.children}
    </Box>
  );
}
