import { Box, SxProps, Theme } from "@mui/material";

export const Center: React.FC<{
  children?: React.ReactNode | undefined;
  sx?: SxProps<Theme> | undefined;
}> = ({ children, sx }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
