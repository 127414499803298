import { TrestleSearchParams } from "../interfaces/TrestleSearchParams";
import isDev from "./IsDevelopmentHelper";

const baseApiURL = new URL(
  window.location.protocol + "//" + window.location.host
);
baseApiURL.port = "8000";
const baseApiUrl = isDev()
  ? baseApiURL.toString().replace(/\/$/, "")
  : "https://graystonerealtyfl-server.herokuapp.com";

const baseTrestleApiUrl = "https://api-prod.corelogic.com/trestle";

export const Urls = {
  walkscore: (
    address: string,
    lattitude: number,
    longitude: number
  ): string => {
    const encodedAddress = encodeURIComponent(address);
    const encodedLattitude = encodeURIComponent(lattitude);
    const encodedLongitude = encodeURIComponent(longitude);
    return `${baseApiUrl}/walkscore?address=${encodedAddress}&lattitude=${encodedLattitude}&longitude=${encodedLongitude}`;
  },
  googleCaptcha: (token: string): URL => {
    return new URL(`${baseApiUrl}/captcha`);
  },
  trestleApiGetToken: (): URL => {
    const url = new URL(`${baseApiUrl}/get-trestle-token`);
    return url;
  },
  trestleApiGetFilteredListings: ({
    filter,
    top,
    select,
    expand,
  }: TrestleSearchParams): URL => {
    const url = new URL(`${baseTrestleApiUrl}/odata/Property`);
    if (filter !== undefined) {
      url.searchParams.append("$filter", filter);
    }

    if (top !== undefined) {
      url.searchParams.append("$top", top);
    }

    if (select !== undefined) {
      url.searchParams.append("$select", select);
    }

    if (expand !== undefined) {
      url.searchParams.append("$expand", expand);
    }

    return url;
  },
  googlePlacesAutocomplete: (input: string) => {
    const url = new URL(`${baseApiUrl}/google-places-autocomplete`);
    url.searchParams.append("input", input);
    return url;
  },
  googleReverseGeocode: (latlng: string) => {
    const url = new URL(`${baseApiUrl}/google-reverse-geocode`);
    url.searchParams.append("latlng", latlng);
    return url;
  },
};
