import { Box, Container, Grid, Skeleton } from "@mui/material";

export default function ListingDetailLoading() {
  const defaultSkeletonStyles = { transform: "none" };

  return (
    <Container>
      <Grid columnSpacing="30px" rowSpacing="30px" container direction="row">
        <Grid item xs={12} md={8}>
          <Skeleton
            height={"600px"}
            animation="wave"
            sx={{
              display: { xs: "block", md: "none" },
              margin: "0 -16px",
              ...defaultSkeletonStyles,
            }}
          />
          <Box
            sx={{ display: { xs: "block", md: "none" } }}
            height="10px"
          ></Box>
          <Grid rowSpacing="10px" direction="row" container>
            <Grid xs={12} md={6} item>
              <Skeleton
                sx={{ marginBottom: 0, ...defaultSkeletonStyles }}
                animation="wave"
                height={40}
                width="80%"
              />
              <Skeleton animation="wave" height={40} width="40%" />
              <Skeleton animation="wave" height={20} width="90%" />
            </Grid>
            <Grid xs={12} md={6} item>
              <Skeleton
                sx={{
                  width: { xs: "60%", md: "40%" },
                  marginLeft: { xs: "none", md: "auto" },
                  ...defaultSkeletonStyles,
                }}
                animation="wave"
                height={40}
              />
              <Skeleton
                sx={{
                  marginLeft: "auto",
                  display: { xs: "none", md: "inherit" },
                }}
                animation="wave"
                height={40}
                width="45%"
              />
            </Grid>
          </Grid>
          <Box height="7px"></Box>
          {/* Mimic listing image slider */}
          <Skeleton
            height={"600px"}
            animation="wave"
            sx={{
              display: { xs: "none", md: "block", ...defaultSkeletonStyles },
            }}
          />
          <Box height="28px"></Box>
          <Skeleton
            height={"150px"}
            animation="wave"
            width="100%"
            sx={{ ...defaultSkeletonStyles }}
          />
          <Box height="28px"></Box>
          <Skeleton
            height={"200px"}
            animation="wave"
            width="100%"
            sx={{ ...defaultSkeletonStyles }}
          />
          <Box height="28px"></Box>
          <Grid
            direction="row"
            rowSpacing={"20px"}
            columnSpacing="20px"
            container
          >
            <Grid item xs={12} md={6}>
              <Skeleton
                sx={{
                  transform: "none",
                }}
                height="441px"
                animation="wave"
                width="100%"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton
                sx={{
                  transform: "none",
                }}
                height="441px"
                animation="wave"
                width="100%"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton
                sx={{
                  transform: "none",
                }}
                height="441px"
                animation="wave"
                width="100%"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton
                sx={{
                  transform: "none",
                }}
                height="441px"
                animation="wave"
                width="100%"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton
            sx={{
              transform: "none",
            }}
            height="705px"
            animation="wave"
            width="100%"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
