import { Pagination } from "swiper";
import { v4 as uuid } from "uuid";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, ButtonBase, Typography } from "@mui/material";
import { containerBorderRadius } from "../constants";
import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SwiperCore from "swiper";

export default function DateCardPicker(props: any) {
  const dates: Date[] = Array.from({ length: 10 }, (_, i) => {
    const now = new Date();
    now.setDate(now.getDate() + i);
    return now;
  });
  const [selected, setSelected] = useState<number>(0);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  return (
    <Box sx={{ position: "relative" }}>
      <ButtonBase
        onClick={() => {
          swiper?.slideNext();
        }}
        sx={{
          position: "absolute",
          top: "20%",
          right: "-30px",
          padding: "8px",
          backgroundColor: "#fff ",
          border: "1px solid #ccc",
          borderRadius: "50%",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 4px 0px",
          zIndex: 2,
        }}
      >
        <ChevronRightIcon sx={{ fontSize: "35px" }} />
      </ButtonBase>

      <ButtonBase
        onClick={() => {
          swiper?.slidePrev();
        }}
        sx={{
          position: "absolute",
          top: "20%",
          left: "-30px",
          padding: "8px",
          backgroundColor: "#fff ",
          border: "1px solid #ccc",
          borderRadius: "50%",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 4px 0px",
          zIndex: 2,
        }}
      >
        <ChevronLeftIcon sx={{ fontSize: "35px" }} />
      </ButtonBase>

      <Swiper
        className="DateCardPicker"
        modules={[Pagination]}
        slidesPerView={3}
        spaceBetween={10}
        scrollbar={{ draggable: true }}
        onSwiper={setSwiper}
        onSlideChange={() => {}}
      >
        {dates.map((date: Date, index: number) => {
          return (
            <SwiperSlide
              onClick={() => {
                setSelected(index);
                props.onDateSelected(date);
              }}
              key={uuid()}
            >
              <DateCard selected={index === selected} date={date} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
}

function DateCard(props: any) {
  const date = props.date as Date;
  const weekDay = date.toLocaleDateString("en-US", {
    weekday: "short",
  });
  const month = date.toLocaleDateString("en-US", {
    month: "short",
  });

  return (
    <Box
      sx={{
        height: "110px",
        border: props.selected ? `1px solid #33beff` : "1px solid #ccc",
        padding: "10px 0",
        color: props.selected ? "#33beff" : "#000",
        borderRadius: containerBorderRadius,
        transition: "color 0.2s ease-in-out",
        "&:hover": {
          color: "#33beff",
        },
      }}
    >
      <Typography textAlign={"center"}>{weekDay}</Typography>
      <Box height="8px"></Box>
      <Typography textAlign={"center"} fontWeight={"bold"}>
        {date.getDate()}
      </Typography>
      <Box height="8px"></Box>
      <Typography textAlign={"center"}>{month}</Typography>
    </Box>
  );
}
