import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Stack, Typography } from "@mui/material";

function valuetext(value: number) {
  return `${value.toFixed(2)}`;
}

function valueLabelFormat(value: number) {
  return `$${value.toLocaleString("en-US", { maximumFractionDigits: 2 })}`;
}

export const ListingSearchPriceRangeSlider: React.FC<{
  onChange: (priceRange: string[]) => void;
}> = ({ onChange }) => {
  const maxPrice = 2500000;
  const [range, setRange] = React.useState<number[]>([0, 300000]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setRange(newValue as number[]);
    onChange((newValue as number[]).map((value: number) => value.toString()));
  };

  return (
    <Stack spacing="8px">
      <Typography id="track-false-slider" gutterBottom>
        Price Range from{" "}
        <span style={{ fontWeight: "400" }}>{valueLabelFormat(range[0])}</span>{" "}
        to{" "}
        <span style={{ fontWeight: "400" }}>{valueLabelFormat(range[1])}</span>
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Slider
          getAriaLabel={() => "Price Range"}
          value={range}
          onChange={handleChange}
          valueLabelDisplay="auto"
          min={0}
          max={maxPrice}
          step={5000}
          getAriaValueText={valuetext}
          valueLabelFormat={valueLabelFormat}
        />
      </Box>
    </Stack>
  );
};
