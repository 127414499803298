import { Backdrop, Paper, Typography } from "@mui/material";
import Background from "../assets/images/contactBackground.jpg";

export default function ImageWithTitle(props: any) {
  return (
    <Paper
      sx={{
        position: "relative",
        height: "120px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${Background})`,
      }}
    >
      <Backdrop
        open={true}
        onClick={() => {}}
        sx={{
          position: "absolute",
          color: "#fff",
          zIndex: 0,
          flexDirection: "column",
          padding: "0 20px",
        }}
      >
        <Typography variant="h2">{props.title}</Typography>
      </Backdrop>
    </Paper>
  );
}
