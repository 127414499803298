import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, ButtonBase, IconButton } from "@mui/material";
import BedIcon from "@mui/icons-material/Bed";
import ShowerIcon from "@mui/icons-material/Shower";
import { Listing } from "../interfaces/Listing";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import IosShareIcon from "@mui/icons-material/IosShare";
import NoImagePlaceHolder from "../assets/images/noImagePlaceholder.png";
import { USDCurrencyFormatter } from "../utils/NumberFormatter";
import { ImagePlaceHolder } from "./ImagePlaceHolder";
import { ListingTag } from "./ListingTag";
import { SearchFieldKeys } from "../enums/SearchFieldKeys";
import { handleSharing } from "../utils/WebShareHelper";

export const ImageListingCard: React.FC<{ listing: Listing }> = ({
  listing,
}) => {
  const iconMarginRight = "4px";

  return (
    <ButtonBase sx={{ width: "100%" }} component="div">
      <Card sx={{ width: "100%", position: "relative" }}>
        {listing.Media !== null && listing.Media !== undefined && (
          <ImagePlaceHolder
            style={{ height: "300px", width: "100%", objectFit: "cover" }}
            alt={listing.UnparsedAddress ?? ""}
            src={listing.Media[0].MediaURL}
            widthLoading={"100%"}
            heightLoading={"300px"}
          />
        )}

        {listing.Media === null && (
          <CardMedia
            height="300px"
            component="img"
            image={NoImagePlaceHolder}
            alt={listing.UnparsedAddress}
          />
        )}

        <div
          style={{
            position: "absolute",
            top: "0px",
            height: "300px",
            left: "0",
            right: "0",
            color: "white",
            backgroundImage:
              "linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,.75) 100%)",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              position: "absolute",
              bottom: "77px",
              left: "17px",
              fontSize: "1.3rem",
              fontWeight: 300,
            }}
          >
            {listing.UnparsedAddress}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              position: "absolute",
              bottom: "47px",
              left: "17px",
              fontSize: "1.3rem",
              fontWeight: 600,
            }}
          >
            {USDCurrencyFormatter.format(listing.ListPrice)}
          </Typography>
          <Box
            sx={{
              position: "absolute",
              bottom: "17px",
              left: "17px",
              fontSize: "1.1rem",
              width: { xs: "250px", sm: "300px" },
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px" }}>
                <BedIcon sx={{ marginRight: iconMarginRight }} />
                <span>{listing.BedroomsTotal}</span>
              </div>

              <div style={{ marginRight: "10px" }}>
                <ShowerIcon sx={{ marginRight: iconMarginRight }} />
                <span>{listing.BathroomsFull}</span>
                {/* TODO: Add half bathrooms */}
              </div>

              <div style={{ marginRight: "10px" }}>
                <DirectionsCarIcon sx={{ marginRight: iconMarginRight }} />
                <span>{listing.CoveredSpaces}</span>
              </div>

              <div>
                <SquareFootIcon sx={{ marginRight: iconMarginRight }} />
                <span style={{ display: "inline-block", width: "20px" }}>
                  {listing.LivingArea}
                </span>
              </div>
            </div>
          </Box>
          <ListingTag
            title={listing.StandardStatus?.toUpperCase() ?? "None"}
            backgroundColor="rgba(0,0,0,.65)"
            sx={{
              position: "absolute",
              top: "17px",
              right: "17px",
              fontSize: "13px",
            }}
          ></ListingTag>
          {listing.PropertyType === "ResidentialLease" && (
            <ListingTag
              backgroundColor="primary.main"
              sx={{ position: "absolute", top: "17px", right: "90px" }}
              title={"For Rent"}
            />
          )}
          {listing.PropertyType === SearchFieldKeys.Residential && (
            <ListingTag
              backgroundColor="primary.main"
              sx={{ position: "absolute", top: "17px", right: "90px" }}
              title={"For Sale"}
            />
          )}

          <IconButton
            style={{
              position: "absolute",
              top: "17px",
              left: "17px",
              padding: "17px",
            }}
            aria-label="share"
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              handleSharing(
                listing.UnparsedAddress ?? "None",
                listing.PublicRemarks ?? ""
              );
            }}
          >
            <IosShareIcon sx={{ color: "white" }} />
          </IconButton>
        </div>
      </Card>
    </ButtonBase>
  );
};
