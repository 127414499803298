import { Box, Container, Grid } from "@mui/material";
import { agents, sectionSpacing } from "../constants";
import SectionTitle from "./SectionTitle";
import AgentCard from "./AgentCard";
import { Fade } from "@mui/material";
import { Agent } from "../interfaces/Agent";
import useVisibility from "../hooks/useVisibility";

export default function MeetAgents() {
  var transitionDelay = 0;
  const [isVisible, currentElement] = useVisibility<HTMLDivElement>();

  return (
    <Box sx={{ backgroundColor: "#f7f8f9" }}>
      <Container>
        <Box sx={{ height: sectionSpacing }}></Box>
        <SectionTitle
          title="Meet Our Agents"
          subtitle="Get to know some of our agents!"
        />
        <Box sx={{ height: "45px" }}></Box>
        <Grid
          ref={currentElement}
          container
          direction="row"
          justifyContent="center"
          rowSpacing="40px"
          position="relative"
        >
          {agents.map((agent: Agent, index: number) => {
            transitionDelay += index === 0 ? 0 : 200;
            return (
              <Grid key={index} item xs={12} md={3}>
                <AnimatedAgentCard
                  active={isVisible}
                  transitionDelay={`${transitionDelay}ms`}
                  agent={agent}
                  borderRight={true}
                />
              </Grid>
            );
          })}
        </Grid>
        <Box sx={{ height: sectionSpacing }}></Box>
      </Container>
    </Box>
  );
}

// Builds an agent card that fades in with a transition delay if
// specified
function AnimatedAgentCard(props: any) {
  return (
    <Fade
      in={props.active}
      timeout={800}
      style={{ transitionDelay: props.transitionDelay }}
    >
      <div>
        <AgentCard agent={props.agent} borderRight={props.borderRight} />
      </div>
    </Fade>
  );
}
