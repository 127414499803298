import axios from "axios";
import { timeout } from "../constants";
import {
  GooglePlace,
  GooglePlaceLengthOffset,
} from "../interfaces/GooglePlace";
import { GoogleReverseGeocode } from "../interfaces/GoogleReverseGeocode";
import { Urls } from "../utils/Urls";

// Gets the autocomplete results for a given input
export const getGooglePlaceSuggestions = async (
  input: string
): Promise<GooglePlace[] | null> => {
  const url = Urls.googlePlacesAutocomplete(input);
  try {
    const response = await axios.post(url.toString(), { timeout: timeout });
    if (response.status === 200) {
      const list = response.data.data as any[];
      return list.map((json: any) => {
        return {
          main_text: json.main_text,
          label: json.label,
          matched_substrings:
            json.matched_substrings as GooglePlaceLengthOffset[],
          reference: json.reference,
          place_id: json.place_id,
        };
      });
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

// Gets the place details for a lattitude and longitude
export const getGoogleReverseGeocode = async (
  latlng: string
): Promise<GoogleReverseGeocode | null> => {
  const url = Urls.googleReverseGeocode(latlng);
  try {
    const response = await axios.post(url.toString(), { timeout: timeout });
    if (response.status === 200) {
      const firstAddress = response.data.data;
      return {
        address_components: firstAddress.address_components,
        formatted_address: firstAddress.formatted_address,
        place_id: firstAddress.place_id,
      };
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};
