import CheckIcon from "@mui/icons-material/Check";
import { Box, Typography } from "@mui/material";

export default function FeatureItem(props: any) {
  return (
    <div style={{ display: "flex" }}>
      <CheckIcon />
      <Box width="5px"></Box>
      <Typography>{props.featureText}</Typography>
    </div>
  );
}
