import {
  Backdrop,
  Box,
  Container,
  Divider,
  Grid,
  Grow,
  Paper,
  Typography,
} from "@mui/material";
import InquiryForm from "./InquiryForm";
import ReasonBlock from "./ReasonBlock";
import Background from "../assets/images/homeWorkWithUsBackground.jpg";
import useVisibility from "../hooks/useVisibility";

export default function InquiryFormSection() {
  const [isVisible, currentElement] = useVisibility<HTMLDivElement>(-200);

  return (
    <Paper
      sx={{
        position: "relative",
        backgroundRepeat: "repeat",
        backgroundSize: "cover",
        backgroundPosition: "center left",
        backgroundImage: `url(${Background})`,
      }}
    >
      <Backdrop
        open={true}
        onClick={() => {}}
        sx={{
          position: "relative",
          color: "#fff",
          zIndex: 0,
          flexDirection: "column",
          backgroundColor: "rgba(0, 51, 90, 0.85)",
          padding: "100px 0",
        }}
      >
        <Container>
          <Grid
            sx={{ alignItems: "center" }}
            container
            direction="row"
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={4}>
              <Box>
                <Typography fontSize={"3.9rem"} variant="h1">
                  Why Graystone Realty?
                </Typography>
                <Box height="40px"></Box>
                <Divider
                  sx={{ width: "100px", borderColor: "white" }}
                ></Divider>
              </Box>
              <Box height="40px"></Box>
              <ReasonBlock
                reasonNumber="01."
                reasonTitle="Over 20 Years Of Experience"
                reasonBody="We've been there. Done that. With over 20 years of experience in the real estate industry, you'll be sure we can find your dream home."
              />
              <Box
                sx={{
                  height: {
                    xs: "40px",
                    md: "0px",
                  },
                }}
              ></Box>
            </Grid>
            <Box height="40px"></Box>
            <Grid item xs={12} md={4}>
              <ReasonBlock
                reasonNumber="02."
                reasonTitle="Expert Knowledge Of the Local Market"
                reasonBody="We've lived in Florida all of our lives, so we know its markets very well. We'll make sure you get the best home in a great area!"
              />
              <Box height="40px"></Box>
              <ReasonBlock
                reasonNumber="03."
                reasonTitle="Construction And Architecture Experts"
                reasonBody="Not only are we realtors, but we handle construction and architecture as well. We have teams that have built everything from single family homes to apartment units."
              />
            </Grid>
            <Grid ref={currentElement} item xs={12} md={4}>
              <Grow in={isVisible} timeout={800}>
                <div>
                  <InquiryForm
                    boxShadow={true}
                    title="Work With Us"
                    body="We are excited to work with you to find your dream home! Contact us by using the form below."
                  />
                </div>
              </Grow>
            </Grid>
          </Grid>
        </Container>
      </Backdrop>
    </Paper>
  );
}
