import { Avatar, Grid, Typography } from "@mui/material";
import { containerBorderRadius } from "../constants";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

export default function AgentCard(props: any) {
  const theme = useTheme();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      rowSpacing="20px"
      sx={{
        transition: "all ease 0.2s",
        position: "relative",
        top: "0",
        padding: "35px",
        backgroundColor: "#fff",
        borderRadius: containerBorderRadius,
        borderRight: props.borderRight
          ? `1px solid ${theme.palette.divider}`
          : "none",
        "&:hover": {
          boxShadow: { md: "rgba(0, 0, 0, 0.2) 0px 70px 70px 0px" },
          top: { md: "-10px" },
        },
      }}
    >
      <Grid item>
        <Avatar
          sx={{ width: 150, height: 150 }}
          alt={props.agent.name}
          src={props.agent.imageUrl}
        />
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          {props.agent.name}
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {props.agent.title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {props.agent.biography.substring(0, 200)}...
        </Typography>
      </Grid>
      <Grid item>
        <Link
          style={{
            textDecoration: "none",
            display: "inline-block",
            padding: "20px",
            color: theme.palette.primary.main,
          }}
          to={`/agent/${props.agent.id}`}
        >
          View Profile
        </Link>
      </Grid>
    </Grid>
  );
}
