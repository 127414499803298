import {
  Container,
  Box,
  Divider,
  Stack,
  Grid,
  Typography,
} from "@mui/material";
import { Listing } from "../interfaces/Listing";
import ErrorBoundary from "./ErrorBoundary";
import ListingDetailAddress from "./ListingDetailAddress";
import ListingDetailDescription from "./ListingDetailDescription";
import { ListingDetailFeatures } from "./ListingDetailFeatures";
import { ListingDetailHeaderInfo } from "./ListingDetailHeaderInfo";
import ListingDetailOverview from "./ListingDetailOverview";
import { ListingDetailWalkScore } from "./ListingDetailWalkScore";
import { ListingImagesSlider } from "./ListingImagesSlider";
import { ListingsRightSidePanel } from "./ListingsRightSidePanel";
import { MoreListingsSection } from "./MoreListingsSection";
import RoundedContainer from "./RoundedContainer";

export const ListingDetailLoaded: React.FC<{ listing: Listing }> = ({
  listing,
}) => {
  const walkScoreErrorWidget = (
    <RoundedContainer>
      <Typography color="red">
        Error: Could not fetch walkscore for this listing.
      </Typography>
    </RoundedContainer>
  );

  const moreListingsErrorWidget = (
    <RoundedContainer>
      <Typography color="red">
        Error: Could not fetch similar listings.
      </Typography>
    </RoundedContainer>
  );

  const getFeatures = (): string[] => {
    const appliances = listing?.Appliances?.split(",");
    const cooling = listing?.Cooling?.split(",");
    const interiorFeatures = listing?.InteriorFeatures?.split(",");
    const parkingFeatures = listing?.ParkingFeatures?.split(",");

    return [
      ...(appliances ?? []),
      ...(cooling ?? []),
      ...(interiorFeatures ?? []),
      ...(parkingFeatures ?? []),
    ];
  };

  return (
    <Container>
      <Grid columnSpacing="30px" rowSpacing="30px" container>
        <Grid item xs={12} md={8}>
          <ListingImagesSlider
            listing={listing}
            sx={{ display: { xs: "block", md: "none" }, margin: "0 -16px" }}
          />
          <Box height="10px"></Box>
          <ListingDetailHeaderInfo listing={listing} />
          <Box height="20px"></Box>
          <ListingImagesSlider
            listing={listing}
            sx={{ display: { xs: "none", md: "block" } }}
          />
          <Divider sx={{ display: { xs: "block", sm: "none" } }} />
          <Box height="20px"></Box>
          <Stack spacing="20px">
            <ListingDetailOverview listing={listing} />
            <ListingDetailDescription listing={listing} />
            <ListingDetailAddress listing={listing} />
            {getFeatures().length !== 0 && (
              <ListingDetailFeatures features={getFeatures()} />
            )}
            <ErrorBoundary errorUi={walkScoreErrorWidget}>
              <ListingDetailWalkScore listing={listing} />
            </ErrorBoundary>
            <ErrorBoundary errorUi={moreListingsErrorWidget}>
              <MoreListingsSection
                listing={listing}
                title={"Similar Listings"}
              />
            </ErrorBoundary>
          </Stack>
        </Grid>
        <Grid
          sx={{
            position: "relative",
            overflow: "visible",
            minHeight: "1px",
          }}
          item
          xs={12}
          md={4}
        >
          <ListingsRightSidePanel listing={listing} />
        </Grid>
      </Grid>
    </Container>
  );
};
