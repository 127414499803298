import { Box, Button, Grid, SelectChangeEvent, TextField } from "@mui/material";
import React, { FormEvent, useRef, useState } from "react";
import { GooglePlace } from "../interfaces/GooglePlace";
import { BathroomsSelectInput } from "./BathroomsSelectInput";
import { BedroomsSelectInput } from "./BedroomsSelectInput";
import { CitiesSelectInput } from "./CitiesSelectInput";
import {
  CurrentLocation,
  GooglePlacesAutocomplete,
} from "./GooglePlacesAutocomplete";
import { ListingSearchPriceRangeSlider } from "./ListingSearchPriceRangeSlider";
import SearchIcon from "@mui/icons-material/Search";
import { StatusSelectInput } from "./StatusSelectInput";
import { SqftSelectInput } from "./SqftSelectInput";
import { SearchListingsFormData } from "../interfaces/SearchListingsFormData";
import { SearchFieldKeys } from "../enums/SearchFieldKeys";
import { ListingSearchRentPriceRangeSlider } from "./ListingSearchRentPriceRangeSlider";
import { getGoogleReverseGeocode } from "../api/GooglePlacesApiClient";
import {
  GoogleReverseGeocode,
  GoogleReverseGeocodeAddressComponent,
} from "../interfaces/GoogleReverseGeocode";

export const SearchListingsForm: React.FC<{
  onSubmit: (data: SearchListingsFormData) => void;
}> = ({ onSubmit }) => {
  // Fields
  const [location, setLocation] = useState<GooglePlace | null>(null);
  const [googleReverseGeocode, setGoogleReverseGeocode] =
    useState<GoogleReverseGeocode | null>(null);
  const [cities, setCities] = useState<string[]>([]);
  const [bedrooms, setBedrooms] = useState<string[]>([]);
  const [bathrooms, setBathrooms] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [sqft, setSqft] = useState<string[]>([]);
  const [zipCode, setZipCode] = useState<string>("");
  const [priceRange, setPriceRange] = useState<string[]>(["0", "300000"]);
  const [rentPriceRange, setRentPriceRange] = useState<string[]>(["0", "4000"]);

  const formRef = useRef<HTMLFormElement | null>(null);

  // Handle the submission of the form
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data: SearchListingsFormData = {
      location: location,
      cities: cities.length === 0 ? [SearchFieldKeys.Any] : cities,
      bedrooms: bedrooms.length === 0 ? [SearchFieldKeys.Any] : bedrooms,
      bathrooms: bathrooms.length === 0 ? [SearchFieldKeys.Any] : bathrooms,
      status: status.length === 0 ? [SearchFieldKeys.Any] : status,
      sqft: sqft.length === 0 ? [SearchFieldKeys.Any] : sqft,
      priceRange: priceRange,
      rentPriceRange: rentPriceRange,
      zipCode: zipCode ?? SearchFieldKeys.Any,
    };
    onSubmit(data);
  };

  const handleBedroomsChanged = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setBedrooms(typeof value === "string" ? value.split(",") : value);
  };

  const handleBathroomsChanged = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setBathrooms(typeof value === "string" ? value.split(",") : value);
  };

  const handleCitiesChanged = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const anyIndex = value.indexOf(SearchFieldKeys.Any);
    if (
      typeof value === "object" &&
      anyIndex === value.length - 1 &&
      value.length > 1
    ) {
      setCities([value[anyIndex]]);
    } else if (
      typeof value === "object" &&
      anyIndex !== -1 &&
      value.length > 1
    ) {
      value.splice(anyIndex, 1);

      setCities(value);
    } else {
      setCities(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handleSqftChanged = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSqft(typeof value === "string" ? value.split(",") : value);
  };

  const handlePriceRangeChanged = (priceRange: string[]) => {
    setPriceRange(priceRange);
  };

  const handleRentPriceRangeChanged = (rentPriceRange: string[]) => {
    setRentPriceRange(rentPriceRange);
  };

  const handleStatusChanged = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const anyIndex = value.indexOf(SearchFieldKeys.Any);
    if (
      typeof value === "object" &&
      anyIndex === value.length - 1 &&
      value.length > 1
    ) {
      setStatus([value[anyIndex]]);
    } else if (
      typeof value === "object" &&
      anyIndex !== -1 &&
      value.length > 1
    ) {
      value.splice(anyIndex, 1);

      setStatus(value);
    } else {
      setStatus(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handleCurrentLocationSet = async (currentLocation: CurrentLocation) => {
    const address = await getGoogleReverseGeocode(
      `${currentLocation.lattitude},${currentLocation.longitude}`
    );
    setGoogleReverseGeocode(address);
    const reverseGeocodeZipCode = address?.address_components.find(
      (addressComponent: GoogleReverseGeocodeAddressComponent) => {
        return addressComponent.types.includes("postal_code");
      }
    );

    if (reverseGeocodeZipCode !== null && reverseGeocodeZipCode !== undefined) {
      setZipCode(reverseGeocodeZipCode.long_name);
    }
  };

  const isValidZipCode = (zipCode: string): boolean => {
    if (zipCode === "") {
      return true;
    }
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);
  };

  const zipCodeHelperText = (): string | undefined => {
    if (zipCode !== undefined) {
      return isValidZipCode(zipCode ?? "")
        ? ""
        : "Please type in a valid zip code";
    }
  };

  return (
    <Box
      ref={formRef}
      onSubmit={handleSubmit}
      component="form"
      noValidate
      autoComplete="off"
    >
      <GooglePlacesAutocomplete
        onChange={(
          e: React.SyntheticEvent<Element, Event>,
          value: GooglePlace | null
        ) => {
          setLocation(value);
        }}
        onCurrentLocationSet={handleCurrentLocationSet}
      />
      <Box height="20px"></Box>
      <Grid container columnSpacing={"20px"} rowSpacing="20px" direction="row">
        <Grid item xs={12} sm={4}>
          <CitiesSelectInput value={cities} onChange={handleCitiesChanged} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BedroomsSelectInput
            value={bedrooms}
            onChange={handleBedroomsChanged}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BathroomsSelectInput
            value={bathrooms}
            onChange={handleBathroomsChanged}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <StatusSelectInput value={status} onChange={handleStatusChanged} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SqftSelectInput value={sqft} onChange={handleSqftChanged} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            value={zipCode}
            error={!isValidZipCode(zipCode)}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              if (event.target.value === "") {
                setZipCode("");
              } else {
                setZipCode(event.target.value);
              }
            }}
            helperText={zipCodeHelperText()}
            type="text"
            fullWidth
            id="reply_to"
            label="Zip Code"
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Box height={{ xs: "40px", md: "20px" }}></Box>
      {(status.toString() === SearchFieldKeys.ForSale ||
        status.toString() === "") && (
        <ListingSearchPriceRangeSlider onChange={handlePriceRangeChanged} />
      )}
      {status.toString() === SearchFieldKeys.ForRent && (
        <ListingSearchRentPriceRangeSlider
          onChange={handleRentPriceRangeChanged}
        />
      )}
      <Box height={{ xs: "40px", md: "20px" }}></Box>
      <Button
        startIcon={<SearchIcon />}
        type="submit"
        color="secondary"
        fullWidth
        size="large"
        variant="contained"
      >
        Search
      </Button>
    </Box>
  );
};
