import { SearchFieldKeys } from "../enums/SearchFieldKeys";
import { GooglePlace } from "../interfaces/GooglePlace";
import { convertUSDToNumber } from "./NumberFormatter";

export const getSearchCitiesFilterString = (cities: string[]) => {
  if (cities.toString() === SearchFieldKeys.Any) {
    return "";
  }

  return ` and (PostalCity in ('${cities.join("','")}'))`;
};

export const getSearchStatusFilterString = (status: string[]) => {
  const copyStatus = [...status];
  if (copyStatus.toString() === SearchFieldKeys.Any) {
    return "";
  }

  if (copyStatus.toString() === "For Sale") {
    copyStatus[0] = "Residential";
  }

  if (copyStatus.toString() === "For Rent") {
    copyStatus[0] = "ResidentialLease";
  }

  return ` and (PropertyType eq '${copyStatus.toString()}')`;
};

export const getSearchBathroomsFilterString = (bathrooms: string[]) => {
  if (bathrooms.toString() === SearchFieldKeys.Any) {
    return "";
  }

  if (bathrooms.toString() === "3+") {
    return " and (BathroomsFull ge 3)";
  }

  return ` and (BathroomsFull eq ${bathrooms.toString()})`;
};

export const getSearchBedroomsFilterString = (bedrooms: string[]) => {
  if (bedrooms.toString() === SearchFieldKeys.Any) {
    return "";
  }

  if (bedrooms.toString() === "3+") {
    return " and (BedroomsTotal ge 3)";
  }

  return ` and (BedroomsTotal eq ${bedrooms.toString()})`;
};

export const getMaxPriceResidentialFilterString = (maxPrice: string[]) => {
  if (maxPrice.toString() === SearchFieldKeys.Any) {
    return "";
  }

  if (maxPrice.toString() === "$500000+") {
    return " and (ListPrice ge 500000)";
  }

  if (maxPrice.length === 1) {
    return ` and (ListPrice le ${convertUSDToNumber(maxPrice[0])})`;
  } else {
    return ` and (ListPrice ge ${convertUSDToNumber(
      maxPrice[0]
    )}) and (ListPrice le ${convertUSDToNumber(maxPrice[1])})`;
  }
};

export const getMaxPriceResidentialLeaseFilterString = (maxPrice: string[]) => {
  if (maxPrice.toString() === SearchFieldKeys.Any) {
    return "";
  }

  if (maxPrice.toString() === "$2000/mo+") {
    return " and (ListPrice ge 2000)";
  }

  if (maxPrice.length === 1) {
    return ` and (ListPrice le ${convertUSDToNumber(
      maxPrice.toString().slice(0, -3)
    )})`;
  } else {
    return ` and (ListPrice ge ${convertUSDToNumber(
      maxPrice[0]
    )}) and (ListPrice le ${convertUSDToNumber(maxPrice[1])})`;
  }
};

export const getSearchSqftFilterString = (sqft: string[]) => {
  if (sqft.toString() === SearchFieldKeys.Any) {
    return "";
  }

  if (sqft.toString() === "3000+") {
    return " and (LivingArea ge 3000)";
  }

  return ` and (LivingArea ge ${sqft[0]}) and (LivingArea le ${sqft[1]})`;
};

export const getLocationFilterString = (location: GooglePlace) => {
  return ` and (UnparsedAddress eq '${location.main_text}')`;
};

export const getSearchZipCodeFilterString = (zipCode: string) => {
  if (zipCode === SearchFieldKeys.Any || zipCode === "") {
    return "";
  }

  return ` and (PostalCode eq '${zipCode}')`;
};
