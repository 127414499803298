import Home from "./screens/Home";
import { Page } from "./interfaces/Page";
import About from "./screens/About";
import Contact from "./screens/Contact";
import { SearchListings } from "./screens/SearchListings";
import { v4 as uuid } from "uuid";
import AgentDetail from "./screens/AgentDetail";
import { Agent } from "./interfaces/Agent";
import { ListingDetail } from "./screens/ListingDetail";
import HomeIcon from "@mui/icons-material/Home";
import ViewListIcon from "@mui/icons-material/ViewList";
import CallIcon from "@mui/icons-material/Call";
import InfoIcon from "@mui/icons-material/Info";
import { Testimonial } from "./interfaces/Testimonial";
import AlanMashraghiImage from "./assets/images/alanMashraghi.jpg";
import BlankProfileImage from "./assets/images/blankProfile.png";

// The menu pages
export const menuPages: Page[] = [
  {
    id: "1",
    name: "Home",
    path: "/",
    element: <Home></Home>,
    mobileIcon: <HomeIcon color="primary" />,
  },
  {
    id: uuid(),
    name: "Listings",
    path: "/search-listings",
    element: <SearchListings />,
    mobileIcon: <ViewListIcon color="primary" />,
  },
  {
    id: uuid(),
    name: "Contact",
    path: "/contact",
    element: <Contact></Contact>,
    mobileIcon: <CallIcon color="primary" />,
  },
  {
    id: uuid(),
    name: "About",
    path: "/about",
    element: <About></About>,
    mobileIcon: <InfoIcon color="primary" />,
  },
];

/// The pages to show details of objects
export const detailPages: Page[] = [
  {
    id: uuid(),
    name: "",
    path: "/agent/:id",
    element: <AgentDetail></AgentDetail>,
  },
  {
    id: uuid(),
    name: "",
    path: "/listing/:id",
    element: <ListingDetail></ListingDetail>,
  },
];

const biographyText =
  "Alan Mashraghi is a Florida-based real estate broker with experience in architecture and construction. With expertise in architecture and several years of work experience in the field, he brings a unique perspective to real estate transactions. Alan's focus is on the Florida real estate market, where he uses his in-depth knowledge to help clients find the perfect property. He is an expert in negotiation and marketing and has a proven track record of successful transactions. Alan is passionate about building strong relationships with his clients and ensuring their satisfaction. In his free time, he enjoys exploring the beaches of Florida and keeping up with the latest trends in the real estate industry. Alan's expertise in both real estate and architecture make him a valuable asset to anyone looking to buy or sell property in Florida.";

// Agents
export const agents: Agent[] = [
  {
    id: "1",
    name: "Alan Mashraghi",
    title: "Broker Agent",
    licenseNumber: "3223747",
    serviceAreas: ["South Florida", "Ocala", "The Villages"],
    specialties: ["Property Management", "Residential Real Estate", "Leasing"],
    languages: ["Farsi", "English", "Spanish"],
    faxNumber: "(561) 351 0909",
    phoneNumber: "(561) 351 0909",
    email: "alan@graystonerealtyfl.com",
    twitterUrl: "",
    instagramUrl: "",
    linkedinUrl: "",
    biography: biographyText,
    imageUrl: AlanMashraghiImage,
  },
  {
    id: "2",
    name: "Gabriel Mashraghi",
    title: "Associate Agent",
    licenseNumber: "3223747",
    serviceAreas: ["South Florida"],
    specialties: [
      "Property Management",
      "Residential Real Estate",
      "Leasing",
      "Construction",
    ],
    languages: ["English", "Spanish"],
    faxNumber: "(561) 346 5722",
    phoneNumber: "(561) 346 5722",
    email: "gabriel@graystonerealtyfl.com",
    twitterUrl: "",
    instagramUrl: "",
    linkedinUrl: "",
    biography:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut molestie eleifend porttitor. Nunc convallis interdum felis a vehicula. Etiam luctus turpis et orci eleifend tincidunt. Phasellus ac suscipit orci, eu luctus eros. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam porttitor, ex sed pellentesque vulputate, odio dui ullamcorper ex, in gravida massa tortor vel dolor. Vestibulum nec ipsum orci. Vestibulum sit amet ante nec dolor dapibus mollis. Nulla posuere lacinia tempor.",
    imageUrl: AlanMashraghiImage,
  },
  {
    id: "3",
    name: "Gabriel Mashraghi",
    title: "Associate Agent",
    licenseNumber: "3223747",
    serviceAreas: ["South Florida"],
    specialties: [
      "Property Management",
      "Residential Real Estate",
      "Leasing",
      "Construction",
    ],
    languages: ["English", "Spanish"],
    faxNumber: "(561) 346 5722",
    phoneNumber: "(561) 346 5722",
    email: "gabriel@graystonerealtyfl.com",
    twitterUrl: "",
    instagramUrl: "",
    linkedinUrl: "",
    biography:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut molestie eleifend porttitor. Nunc convallis interdum felis a vehicula. Etiam luctus turpis et orci eleifend tincidunt. Phasellus ac suscipit orci, eu luctus eros. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam porttitor, ex sed pellentesque vulputate, odio dui ullamcorper ex, in gravida massa tortor vel dolor. Vestibulum nec ipsum orci. Vestibulum sit amet ante nec dolor dapibus mollis. Nulla posuere lacinia tempor.",
    imageUrl: AlanMashraghiImage,
  },
  {
    id: "4",
    name: "Gabriel Mashraghi",
    title: "Associate Agent",
    licenseNumber: "3223747",
    serviceAreas: ["South Florida"],
    specialties: [
      "Property Management",
      "Residential Real Estate",
      "Leasing",
      "Construction",
    ],
    languages: ["English", "Spanish"],
    faxNumber: "(561) 346 5722",
    phoneNumber: "(561) 346 5722",
    email: "gabriel@graystonerealtyfl.com",
    twitterUrl: "",
    instagramUrl: "",
    linkedinUrl: "",
    biography:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut molestie eleifend porttitor. Nunc convallis interdum felis a vehicula. Etiam luctus turpis et orci eleifend tincidunt. Phasellus ac suscipit orci, eu luctus eros. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam porttitor, ex sed pellentesque vulputate, odio dui ullamcorper ex, in gravida massa tortor vel dolor. Vestibulum nec ipsum orci. Vestibulum sit amet ante nec dolor dapibus mollis. Nulla posuere lacinia tempor.",
    imageUrl: AlanMashraghiImage,
  },
];

export const testimonials: Testimonial[] = [
  {
    body: "Working with Alan and Graystone has been a pleasure! He always makes sure I am taken care of, and that we get the best deal possible.",
    name: "Otto H.",
    title: "CEO",
    company: "Otto's Auto",
    imageUrl: BlankProfileImage,
  },
  {
    body: "Whenever I am with Alan, he makes me feel like a king. I always get what I need from him on time, and he always fights for the best possible price!",
    name: "Hoogland H.",
    title: "Manager",
    company: "Company Inc.",
    imageUrl: BlankProfileImage,
  },
  {
    body: "I have been working with Alan for over 20 years. Every property he has sold for me has made a profit! If you work with Alan, you will get exactly what you want.",
    name: "Sam J.",
    title: "Manager",
    company: "Samjack Blackstone",
    imageUrl: BlankProfileImage,
  },
];

export const sectionSpacing = "45px";
export const containerBorderRadius = "4px";

// Colors
export const sectionBackgroundColor = "#f7f8f9";

/// Api timeout
export const timeout = 10000;

/// Search form cities
export const palmBeachCities = [
  "Atlantis",
  "Belle Glade",
  "Boca Raton",
  "Boynton Beach",
  "Briny Breezes",
  "Delray Beach",
  "Golf",
  "Greenacres",
  "Haverhill",
  "Highland Beach",
  "Hypoluxo",
  "Juno Beach",
  "Jupiter",
  "Jupiter Inlet Colony",
  "Lake Clarke Shores",
  "Lake Park",
  "Lake Worth Beach",
  "Lantana",
  "Manalapan",
  "Mangonia Park",
  "North Palm Beach",
  "Ocean Ridge",
  "Pahokee",
  "Palm Beach",
  "Palm Beach Gardens",
  "Palm Beach Shores",
  "Palm Springs",
  "Riviera Beach",
  "Royal Palm Beach",
  "South Bay",
  "South Palm Beach",
  "Tequesta",
  "Wellington",
  "West Palm Beach",
  "Westlake",
];

export const listingFields = [
  "ListingKey",
  "StandardStatus",
  "UnparsedAddress",
  "Appliances",
  "BathroomsFull",
  "BathroomsHalf",
  "BedroomsTotal",
  "BuyerAgentEmail",
  "BuyerAgentFullName",
  "BuyerAgentOfficePhone",
  "BuyerOfficeEmail",
  "BuyerOfficePhone",
  "Cooling",
  "CoveredSpaces",
  "ExteriorFeatures",
  "Flooring",
  "Heating",
  "InteriorFeatures",
  "Latitude",
  "Longitude",
  "LivingArea",
  "ListingId",
  "ParkingFeatures",
  "PublicRemarks",
  "YearBuilt",
  "PostalCity",
  "PostalCode",
  "PropertyType",
  "ActivationDate",
  "ListPrice",
  "StateOrProvince",
  "CountyOrParish",
];
