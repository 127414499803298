import { createTheme, Theme } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";
/**
 * The themeing of the web application
 */
export const theme: Theme = createTheme({
  typography: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    sectionBackgroundColor: {
      main: "#f7f8f9",
    },
    primary: {
      main: "#004274",
    },
    secondary: {
      main: "#33beff",
    },
    divider: "#939696",
    background: {
      default: "red",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltipArrow: {
          "& .MuiTooltip-arrow": {
            color: "#fff",
          },
        },
        tooltipPlacementTop: {
          height: "100px",
          width: "200px",
          color: "black",
          backgroundColor: "white",
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: "#ffffff",
            height: "100%",
            maxHeight: "60px",
          },
        },
      ],
    },
  },
});

theme.typography.h1 = {
  fontSize: "2.5rem",
  fontWeight: "100",
};

theme.typography.h2 = {
  fontSize: "2rem",
  fontWeight: "100",
};

theme.typography.h3 = {
  fontSize: "1.8rem",
  fontWeight: "100",
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};

theme.typography.h4 = {
  fontSize: "0.9rem",
  fontWeight: "bold",
};

theme.typography.body1 = {
  fontWeight: "100",
};
