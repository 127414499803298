import { MenuItem, OutlinedInput, Select } from "@mui/material";
import uuid from "react-uuid";
import { SelectProps } from "../interfaces/SelectProps";

export const StatusSelectInput: React.FC<SelectProps> = ({
  value,
  onChange,
}) => {
  return (
    <Select
      sx={{ width: "100%" }}
      displayEmpty
      value={value}
      onChange={onChange}
      input={<OutlinedInput />}
      renderValue={(selected: string[]) => {
        if (selected.length === 0) {
          return "Status";
        }

        return selected.join(", ");
      }}
      inputProps={{ "aria-label": "Status" }}
    >
      <MenuItem disabled value="">
        Status
      </MenuItem>
      <MenuItem key={uuid()} value="For Sale">
        For Sale
      </MenuItem>
      <MenuItem key={uuid()} value="For Rent">
        For Rent
      </MenuItem>
    </Select>
  );
};
