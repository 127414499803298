import { Stack, Typography, Divider, Grid, Button } from "@mui/material";
import { Listing } from "../interfaces/Listing";
import RoundedContainer from "./RoundedContainer";
import MapIcon from "@mui/icons-material/Map";
import RowWithBottomDivider from "./RowWithBottomDivider";

export default function ListingAddress(props: any) {
  const listing = props.listing as Listing;
  return (
    <RoundedContainer>
      <Stack spacing="20px">
        <Grid direction="row" rowSpacing={"10px"} container>
          <Grid xs={12} sm={6} item>
            <Typography
              fontWeight="400"
              fontSize={{ xs: "1.5rem", sm: "1rem" }}
              variant="body1"
            >
              Address
            </Typography>
          </Grid>
          <Grid
            sx={{ textAlign: { xs: "left", sm: "right" } }}
            xs={12}
            sm={6}
            item
          >
            <Button
              startIcon={<MapIcon />}
              onClick={() => {
                window.open(
                  `https://maps.google.com/?q=${listing.Latitude ?? 0},${
                    listing.Longitude ?? 0
                  }`,
                  "_blank"
                );
              }}
              variant="outlined"
            >
              See In Google Maps
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ display: { xs: "none", sm: "block" } }} />
        <Grid
          container
          columnSpacing={{ xs: "0", sm: "20px", md: "20px" }}
          rowSpacing={{ xs: "10px", sm: "10px", md: "0" }}
          direction="row"
        >
          <Grid item xs={12} sm={6}>
            <Stack spacing="10px">
              <RowWithBottomDivider
                title="Address"
                titleValue={listing.UnparsedAddress}
              />
              <RowWithBottomDivider
                title="City"
                titleValue={listing.PostalCity}
              />
              <RowWithBottomDivider
                title="State"
                titleValue={listing.StateOrProvince}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing="10px">
              <RowWithBottomDivider
                title="Zip Code"
                titleValue={listing.PostalCode}
              />
              <RowWithBottomDivider
                title="County"
                titleValue={listing.CountyOrParish}
              />
              <RowWithBottomDivider
                title="Country"
                titleValue="United States"
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </RoundedContainer>
  );
}
