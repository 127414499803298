import axios from "axios";
import { timeout } from "../constants";
import { Listing } from "../interfaces/Listing";
import { TrestleSearchParams } from "../interfaces/TrestleSearchParams";
import { TrestleToken } from "../interfaces/TrestleToken";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";
import { Urls } from "../utils/Urls";

// Gets a trestle token from the server
export const getTrestleToken = async (): Promise<TrestleToken | null> => {
  const url = Urls.trestleApiGetToken();
  try {
    const response = await axios.get(url.toString(), { timeout: timeout });
    return response.data.data as TrestleToken;
  } catch (error) {
    console.error(error);
  }

  return null;
};

// Gets listings from Trestle by a filter string
export const getTrestleFilteredListings = async ({
  filter,
  top,
  select,
  expand,
}: TrestleSearchParams): Promise<Listing[] | null> => {
  const url = Urls.trestleApiGetFilteredListings({
    filter,
    top,
    select,
    expand,
  });
  const trestleTokenJson = localStorage.getItem(LocalStorageKeys.trestleToken);
  if (trestleTokenJson === null) {
    throw Error("No Trestle token in local storage");
  }

  const trestleToken = JSON.parse(trestleTokenJson) as TrestleToken;
  try {
    const response = await axios.get(decodeURIComponent(url.toString()), {
      timeout: timeout,
      headers: {
        Authorization: `Bearer ${trestleToken.access_token}`,
      },
    });
    return response.data.value as Listing[];
  } catch (error) {
    console.error(error);
    throw error;
  }
};
