import { Box, Container, Grid } from "@mui/material";
import { useEffect } from "react";
import ContactInfoBox from "../components/ContactInfoBox";
import ErrorBoundary from "../components/ErrorBoundary";
import Footer from "../components/Footer";
import ImageWithTitle from "../components/ImageWithTitle";
import InquiryForm from "../components/InquiryForm";
import { ResponsiveAppBar } from "../components/ResponsiveAppBar";
import { containerBorderRadius, sectionBackgroundColor } from "../constants";

export default function Contact() {
  useEffect(() => {
    document.title = "Contact Us";
  }, []);

  return (
    <ErrorBoundary>
      <Box
        sx={{
          backgroundColor: sectionBackgroundColor,
          borderRadius: containerBorderRadius,
        }}
      >
        <ResponsiveAppBar position={{ xs: "static" }} />
        <ImageWithTitle title="Get In Touch" />
        <Container>
          <Box height="45px"></Box>
          <Grid
            container
            direction="row"
            rowSpacing="30px"
            columnSpacing="30px"
          >
            <Grid item xs={12} md={8}>
              <InquiryForm
                title="Contact Us"
                body="We'd love to help you find your dream home! You can contact us by filling out the form below."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ContactInfoBox />
            </Grid>
          </Grid>
          <Box height="45px"></Box>
        </Container>
        <Footer />
      </Box>
    </ErrorBoundary>
  );
}
