import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ReactComponent as Logo } from "../assets/images/logoWithoutRealty.svg";
import { GraystoneDrawer } from "./GraystoneDrawer";
import { menuPages } from "../constants";
import { Page } from "../interfaces/Page";
import { Link } from "react-router-dom";
import { Fab, Grid, IconButton } from "@mui/material";
import { useEffect, useState } from "react";

/**
 * The navigation bar used throughout the website
 */
export const ResponsiveAppBar = (props: any) => {
  const logoWidth: number = 80;
  const logoColor: string = "#fff";

  const [scrollY, setScrollY] = useState<number>(0);

  useEffect(() => {
    window.onscroll = () => {
      setScrollY(window.scrollY);
      return () => (window.onscroll = null);
    };
  }, []);

  return (
    <>
      <AppBar
        sx={{
          paddingTop: "10px",
          paddingBottom: "10px",
          position: props.position,
          backgroundColor: props.backgroundColor,
          height: { md: "90px" },
          boxShadow: { md: "none" },
          borderBottom: { md: "2px solid rgba(255, 255, 255, 0.4)" },
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <GraystoneDrawer pages={menuPages} />
            <Grid
              container
              direction="row"
              alignItems="center"
              sx={{
                justifyContent: {
                  md: "space-between",
                  xs: "center",
                },
              }}
            >
              <Grid item>
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <Logo fill={logoColor} stroke={logoColor} width={logoWidth} />
                </Typography>

                <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 2,
                    display: { xs: "flex", md: "none" },
                    justifyContent: "center",
                    flexGrow: 1,
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <Logo fill={logoColor} stroke={logoColor} width={logoWidth} />
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  columnSpacing="10px"
                  container
                  direction="row"
                  sx={{ display: { xs: "none", md: "flex" } }}
                >
                  {menuPages.map((page: Page, index: number) => {
                    return (
                      <Grid item key={page.id}>
                        <Link
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            textDecoration: "none",
                            color: "inherit",
                            display: "inline-block",
                          }}
                          to={page.path}
                        >
                          {page.name.toUpperCase()}
                        </Link>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Box width={{ xs: "0", md: "30px" }}></Box>
            <IconButton
              disableRipple={true}
              sx={{
                display: { md: "inline-flex" },
                padding: { xs: "20px" },
                color: "#fff",
              }}
              onClick={() => {
                window.location.href = "tel://+15613510909";
              }}
            >
              <LocalPhoneIcon
                sx={{ color: "#fff", fontSize: { xs: "24px", md: "19px" } }}
              />
              <Box width={{ xs: "0", md: "10px" }}></Box>
              <Typography
                sx={{ display: { xs: "none", md: "block", fontSize: "15px" } }}
                variant="body1"
              >
                {"(561) 351 0909"}
              </Typography>
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <Fab
        onClick={() => {
          window.location.href = "tel://+15613510909";
        }}
        sx={{
          display: scrollY <= 100 ? "none" : "inline-block",
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
        color="secondary"
        aria-label="call"
      >
        <LocalPhoneIcon sx={{ color: "white" }} />
      </Fab>
    </>
  );
};
