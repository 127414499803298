import axios from "axios";
import { timeout } from "../constants";
import { WalkScore } from "../interfaces/WalkScore";
import { Urls } from "../utils/Urls";

// Gets the walk, bike, and transit score info if available
export const getWalkScore = async (
  address: string,
  lattitude: number,
  longitude: number
): Promise<WalkScore | null> => {
  const url = Urls.walkscore(address, lattitude, longitude);
  try {
    const response = await axios.get(url, { timeout: timeout });
    return response.data.data as WalkScore;
  } catch (error) {
    console.error(error);
  }

  return null;
};
