import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import {
  emailJsServiceId,
  emailsJsPublicKey,
  emailJsTourFormTemplateId,
} from "../credentials/EmailJsCredentials";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { getCaptchaScore } from "../api/GoogleCaptchaApiClient";
import PrivacyPolicyPDF from "../assets/PrivacyPolicy.pdf";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DateCardPicker from "./DateCardPicker";
import { Listing } from "../interfaces/Listing";
import { captchaSiteKey } from "../credentials/GoogleRecaptchaCredentials";

export default function ScheduleTourForm(props: any) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const listing = props.listing as Listing;
  const form = useRef<HTMLFormElement | null>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);

  // States
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  // Fields
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phoneNumber, setPhone] = useState<string>();
  const [message, setMessage] = useState<string>(
    `I am interested in touring the property located at ${listing.UnparsedAddress}.`
  );
  const [date, setDate] = useState<Date>(new Date());
  const [checked, setChecked] = useState<boolean | undefined>();
  const [time, setTime] = React.useState<Dayjs | null>(dayjs());

  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSuccess(false);
    setError(false);
    setLoading(false);

    // Check if the captcha is checked before proceeding
    const token = captchaRef?.current?.getValue();
    captchaRef?.current?.reset();

    if (token === null || token === "" || token === undefined) {
      setChecked(false);
      return;
    }

    setLoading(true);

    /// Make an API call to google captcha service to determine if the user is human
    const isHuman = await getCaptchaScore(token);
    if (!isHuman) {
      setSuccess(false);
      setLoading(false);
      setError(true);
      return;
    }

    await emailjs
      .sendForm(
        emailJsServiceId,
        emailJsTourFormTemplateId,
        form.current!,
        emailsJsPublicKey
      )
      .then(
        (result) => {
          form.current?.reset();
          setError(false);
          setSuccess(true);
          setLoading(false);
          setChecked(undefined);
        },
        (error) => {
          console.error(error.text);
          setSuccess(false);
          setLoading(false);
          setError(true);
        }
      );
  };

  const handleChange = (newValue: Dayjs | null) => {
    setTime(newValue);
  };

  return (
    <form onSubmit={sendEmail} ref={form}>
      <FormControl sx={{ width: "100%" }}>
        <Typography variant="body1">
          Schedule a tour by filling out the form below.
        </Typography>
        <Box height="20px"></Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCardPicker
            onDateSelected={(date: Date) => {
              setDate(date);
            }}
          />

          <Box height="20px"></Box>
          <MobileTimePicker
            label="Time"
            value={time}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <input
            name="time"
            type="hidden"
            value={time!.format("h:mm A")}
          ></input>
          <input
            type="hidden"
            name="date"
            value={date.toLocaleDateString("en-US")}
          ></input>
          <input
            name="address"
            type="hidden"
            value={listing.UnparsedAddress ?? ""}
          ></input>
          <input
            name="city"
            type="hidden"
            value={listing.PostalCity ?? ""}
          ></input>
          <input
            name="state"
            type="hidden"
            value={listing.StateOrProvince ?? ""}
          ></input>
          <input
            name="zipCode"
            type="hidden"
            value={listing.PostalCode ?? ""}
          ></input>
        </LocalizationProvider>
        <Box height="20px"></Box>

        <Grid container rowSpacing={"20px"} columnSpacing={"20px"}>
          <Grid item xs={12}>
            <TextField
              error={name === ""}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setName(event.target.value)}
              helperText={name === "" ? "Please type in your name" : ""}
              required
              fullWidth
              id="from_name"
              label="Name"
              variant="outlined"
              name="from_name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={phoneNumber === ""}
              onChange={(event) => setPhone(event.target.value)}
              helperText={
                phoneNumber === "" ? "Please type in your phone number" : ""
              }
              required
              type="tel"
              fullWidth
              id="phone_number"
              label="Phone Number"
              variant="outlined"
              name="phone_number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={email === ""}
              onChange={(event) => setEmail(event.target.value)}
              helperText={email === "" ? "Please type in your email" : ""}
              required
              type="email"
              fullWidth
              id="email"
              label="Email"
              variant="outlined"
              name="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={message === ""}
              onChange={(event) => setMessage(event.target.value)}
              helperText={message === "" ? "Please type in your message" : ""}
              required
              fullWidth
              value={message}
              id="message"
              name="message"
              label="Your Message"
              variant="outlined"
              multiline={true}
              rows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography color="#000">
              By submitting this form, I agree to the{" "}
              <a href={PrivacyPolicyPDF} rel="noreferrer" target="_blank">
                Terms of Use
              </a>
              .
            </Typography>
          </Grid>
          {loading && (
            <Grid item xs={12}>
              <Alert icon={<CircularProgress size={20} />} severity="info">
                Sending your tour request...
              </Alert>
            </Grid>
          )}
          {success && (
            <Grid item xs={12}>
              <Alert severity="success">Success! Your message was sent.</Alert>
            </Grid>
          )}
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">
                Your message was not sent. Please try again.
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <div
              style={{
                transform: matches ? "scale(0.75)" : "none",
                transformOrigin: "0 0",
              }}
            >
              <ReCAPTCHA ref={captchaRef} sitekey={captchaSiteKey} />
            </div>
          </Grid>
          {checked === false && (
            <Grid item xs={12}>
              <Typography color="error">Please check the box above.</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              color="secondary"
              type="submit"
              fullWidth
              size="large"
              variant="contained"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </form>
  );
}
