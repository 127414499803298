import { Select, OutlinedInput, MenuItem } from "@mui/material";
import uuid from "react-uuid";
import { SearchFieldKeys } from "../enums/SearchFieldKeys";
import { SelectProps } from "../interfaces/SelectProps";

export const MaxResidentialLeasePriceSelectInput: React.FC<SelectProps> = ({
  value,
  onChange,
}) => {
  return (
    <Select
      sx={{ width: "100%" }}
      displayEmpty
      value={value}
      onChange={onChange}
      input={<OutlinedInput />}
      renderValue={(selected: string[]) => {
        if (selected.length === 0) {
          return "Max Price";
        }

        return selected.join(", ");
      }}
      inputProps={{ "aria-label": "Max Price" }}
    >
      <MenuItem disabled value="">
        Max Price
      </MenuItem>
      <MenuItem key={uuid()} value={SearchFieldKeys.Any}>
        Any
      </MenuItem>
      <MenuItem key={uuid()} value="$1000/mo">
        $1000/mo
      </MenuItem>
      <MenuItem key={uuid()} value="$1500/mo">
        $1500/mo
      </MenuItem>
      <MenuItem key={uuid()} value="$1800/mo">
        $1800/mo
      </MenuItem>
      <MenuItem key={uuid()} value="$2000/mo">
        $2000/mo
      </MenuItem>
      <MenuItem key={uuid()} value="$2000/mo+">
        $2000/mo+
      </MenuItem>
    </Select>
  );
};
