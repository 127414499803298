import { Typography, Divider } from "@mui/material";

export default function RowWithBottomDivider(props: any) {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography fontWeight={"bold"}>{props.title}</Typography>
        <Typography>{props.titleValue}</Typography>
      </div>
      <Divider />
    </>
  );
}
