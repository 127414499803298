import { Listing } from "../interfaces/Listing";
import { ListingCard } from "./ListingCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "swiper/css";
import SwiperCore, { Pagination } from "swiper";
import "swiper/css/pagination";
import { Box } from "@mui/material";
import { listingFields } from "../constants";
import { Autoplay } from "swiper";
import LoadingListingCard from "./LoadingListingCard";
import { useState, useEffect } from "react";
import { getTrestleFilteredListings } from "../api/TrestleApiClient";
import { subscribe } from "../utils/Events";
import { EventType } from "../enums/EventType";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";
SwiperCore.use([Pagination]);

export const ListingsSlider = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [listings, setListings] = useState<Listing[]>([]);
  const dummyLoadingListings = Array.from({ length: 3 }, (_, i) => {
    return (
      <SwiperSlide key={i}>
        <LoadingListingCard />
        <Box height="70px"></Box>
      </SwiperSlide>
    );
  });

  const getListingsAsync = async () => {
    const hasToken =
      localStorage.getItem(LocalStorageKeys.trestleToken) !== null;

    if (hasToken) {
      const listings = await getTrestleFilteredListings({
        top: "9",
        select: listingFields.join(","),
        filter: "BuyerOfficeName eq 'Graystone Realty LLC'",
        expand: "Media($select=MediaURL;$orderby=Order)",
      });
      setListings(listings ?? []);
    } else {
      subscribe(EventType.TrestleTokenFetched, async () => {
        const listings = await getTrestleFilteredListings({
          filter: "BuyerOfficeName eq 'Graystone Realty LLC'",
          top: "10",
          expand: "Media($select=MediaURL;$top=1;$orderby=Order)",
          select: listingFields.join(","),
        });
        setListings(listings ?? []);
      });
    }
  };

  useEffect(() => {
    /// Get listings
    getListingsAsync();
  }, []);

  return (
    <>
      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        slidesPerView={matches ? 3 : 1}
        spaceBetween={50}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => {}}
        onSlideChange={() => {}}
      >
        {listings.length === 0 && dummyLoadingListings}
        {listings.length > 0 &&
          listings.map((listing: Listing) => {
            return (
              <SwiperSlide key={listing.ListingKey}>
                <ListingCard listing={listing} />
                <Box height="70px"></Box>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </>
  );
};
