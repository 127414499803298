import React from "react";
import GoogleMapReact from "google-map-react";
import { googleMapsApiKey } from "../credentials/GooglePlacesCredentials";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, CardMedia, Stack, Tooltip, Typography } from "@mui/material";
import { Listing } from "../interfaces/Listing";
import { USDCurrencyFormatter } from "../utils/NumberFormatter";
import NoImagePlaceHolder from "../assets/images/noImagePlaceholder.png";
import { ImagePlaceHolder } from "./ImagePlaceHolder";

interface MarkerProps {
  listing: Listing;
  lat: number;
  lng: number;
}

interface GoogleMapProps {
  listings?: Listing[];
}

const Marker: React.FC<MarkerProps> = ({ listing }) => {
  const tooltipContent = (
    <Box sx={{ display: "flex" }}>
      {listing.Media !== undefined && listing.Media !== null && (
        <ImagePlaceHolder
          src={listing.Media[0].MediaURL}
          alt={listing.UnparsedAddress!}
          style={{ width: "90px", height: "90px", objectFit: "cover" }}
          widthLoading={"90px"}
          heightLoading={"90px"}
        />
      )}

      {listing.Media === null && (
        <CardMedia
          height="300px"
          component="img"
          image={NoImagePlaceHolder}
          alt={listing.UnparsedAddress}
        />
      )}

      <Box width="10px"></Box>
      <Stack spacing={"8px"}>
        <Typography>{listing.UnparsedAddress}</Typography>
        <Typography>
          {USDCurrencyFormatter.format(listing.ListPrice)}
        </Typography>
        <Typography>{listing.PropertyType}</Typography>
      </Stack>
    </Box>
  );
  return (
    <Tooltip arrow placement="top" title={tooltipContent}>
      <LocationOnIcon sx={{ fontSize: "2.5rem", color: "secondary.main" }} />
    </Tooltip>
  );
};

export const GoogleMap: React.FC<GoogleMapProps> = ({ listings }) => {
  const defaultProps = {
    center: {
      lat: 27.01019643193155,
      lng: -80.44529687868894,
    },
    zoom: 9,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsApiKey }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {listings?.map((listing: Listing, index: number) => {
          return (
            <Marker
              key={index}
              listing={listing}
              lat={listing.Latitude ?? 0 + Math.random()}
              lng={listing.Longitude ?? 0 - Math.random()}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};
