import { Box, Divider, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { getTrestleFilteredListings } from "../api/TrestleApiClient";
import { listingFields, sectionSpacing } from "../constants";
import { EventType } from "../enums/EventType";
import { Listing } from "../interfaces/Listing";
import { subscribe } from "../utils/Events";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";
import { ListingCard } from "./ListingCard";
import LoadingListingCard from "./LoadingListingCard";

export const MoreListingsSection: React.FC<{
  listing: Listing;
  title: string;
}> = ({ listing, title }) => {
  const [listings, setListings] = useState<Listing[]>([]);
  const [error, setError] = useState<Error | null>(null);

  if (error) {
    throw error;
  }

  const dummyLoadingListings = Array.from({ length: 4 }, (_, i) => {
    return (
      <Grid key={i} item xs={12} md={6}>
        <LoadingListingCard />
      </Grid>
    );
  });

  const getListingsAsync = async () => {
    const hasToken =
      localStorage.getItem(LocalStorageKeys.trestleToken) !== null;

    const propertyType = listing.PropertyType;
    const postalCode = listing.PostalCode;

    if (hasToken) {
      try {
        const listings = await getTrestleFilteredListings({
          top: "6",
          select: listingFields.join(","),
          filter: `(StandardStatus eq 'Active') and (PropertyType eq '${
            propertyType ?? "Residential"
          }') and (PostalCode eq '${postalCode ?? "33472"}')`,
          expand: "Media($select=MediaURL;$orderby=Order)",
        });
        setListings(listings ?? []);
      } catch (error) {
        setError(error as AxiosError);
      }
    } else {
      subscribe(EventType.TrestleTokenFetched, async () => {
        const listings = await getTrestleFilteredListings({
          top: "9",
          select: listingFields.join(","),
          filter:
            "(StandardStatus eq 'Active') and (PropertyType eq 'Residential')",
          expand: "Media($select=MediaURL;$top=1;$orderby=Order)",
        });
        setListings(listings ?? []);
      });
    }
  };

  useEffect(() => {
    getListingsAsync();
  }, []);

  return (
    <Box>
      <Stack spacing="20px">
        <Typography
          fontWeight="400"
          fontSize={{ xs: "1.5rem", sm: "1rem" }}
          variant="body1"
        >
          {title}
        </Typography>
        <Divider sx={{ display: { xs: "none", sm: "block" } }} />
      </Stack>
      <Box height="20px"></Box>
      <Grid direction="row" rowSpacing={"20px"} columnSpacing="20px" container>
        {listings.length === 0 && dummyLoadingListings}
        {listings.length !== 0 &&
          listings.map((listing: Listing, index: number) => {
            return (
              <Grid key={listing.ListingKey} item xs={12} md={6}>
                <ListingCard listing={listing} />
              </Grid>
            );
          })}
      </Grid>

      <Box height={sectionSpacing}></Box>
    </Box>
  );
};
