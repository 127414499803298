import { Box, SxProps, Theme, Typography } from "@mui/material";

import { containerBorderRadius } from "../constants";

export const ListingTag: React.FC<{
  title: string;
  backgroundColor?: string;
  sx?: SxProps<Theme> | undefined;
}> = ({ title, backgroundColor, sx }) => {
  return (
    <Box
      sx={{
        backgroundColor: backgroundColor ?? "rgba(0, 0, 0, 0.65)",
        display: "inline-block",
        color: "#fff",
        padding: "2px 8px",
        borderRadius: containerBorderRadius,
        ...sx,
      }}
    >
      <Typography fontWeight={"bold"} fontSize={"0.8rem"}>
        {title.toUpperCase()}
      </Typography>
    </Box>
  );
};
