import { MenuItem, OutlinedInput, Select } from "@mui/material";
import React from "react";
import uuid from "react-uuid";
import { SearchFieldKeys } from "../enums/SearchFieldKeys";
import { SelectProps } from "../interfaces/SelectProps";

export const SqftSelectInput: React.FC<SelectProps> = ({ value, onChange }) => {
  return (
    <Select
      required
      sx={{ width: "100%" }}
      displayEmpty
      value={value}
      onChange={onChange}
      input={<OutlinedInput />}
      renderValue={(selected: string[]) => {
        if (selected.length === 0) {
          return "Sqft";
        }

        return selected.join(", ");
      }}
      inputProps={{ "aria-label": "Square Feet" }}
    >
      <MenuItem disabled value="">
        Sqft
      </MenuItem>
      <MenuItem key={uuid()} value={SearchFieldKeys.Any}>
        Any
      </MenuItem>
      <MenuItem key={uuid()} value="0-1000">
        0 - 1000
      </MenuItem>
      <MenuItem key={uuid()} value="1000-2000">
        1000 - 2000
      </MenuItem>
      <MenuItem key={uuid()} value="3000+">
        3000+
      </MenuItem>
    </Select>
  );
};
