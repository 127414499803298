import { Select, OutlinedInput, MenuItem } from "@mui/material";
import uuid from "react-uuid";
import { SearchFieldKeys } from "../enums/SearchFieldKeys";
import { SelectProps } from "../interfaces/SelectProps";

export const MaxResidentialPriceSelectInput: React.FC<SelectProps> = ({
  value,
  onChange,
}) => {
  return (
    <Select
      sx={{ width: "100%" }}
      displayEmpty
      value={value}
      onChange={onChange}
      input={<OutlinedInput />}
      renderValue={(selected: string[]) => {
        if (selected.length === 0) {
          return "Max Price";
        }

        return selected.join(", ");
      }}
      inputProps={{ "aria-label": "Max Price" }}
    >
      <MenuItem disabled value="">
        Max Price
      </MenuItem>
      <MenuItem key={uuid()} value={SearchFieldKeys.Any}>
        Any
      </MenuItem>
      <MenuItem key={uuid()} value="$100,000">
        $100,000
      </MenuItem>
      <MenuItem key={uuid()} value="$200,000">
        $200,000
      </MenuItem>
      <MenuItem key={uuid()} value="$300,000">
        $300,000
      </MenuItem>
      <MenuItem key={uuid()} value="$400,000">
        $400,000
      </MenuItem>
      <MenuItem key={uuid()} value="$500,000+">
        $500,000+
      </MenuItem>
    </Select>
  );
};
