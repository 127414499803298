import { Backdrop, Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Background from "../assets/images/homeJumbotronBackground.jpg";

export default function Jumbotron() {
  return (
    <Paper
      sx={{
        textAlign: "center",
        position: "relative",
        height: "500px",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: `url(${Background})`,
      }}
    >
      <Backdrop
        open={true}
        onClick={() => {}}
        sx={{
          position: "absolute",
          color: "#fff",
          zIndex: 0,
          flexDirection: "column",
          padding: "0 20px",
        }}
      >
        <Typography variant="h1">Graystone Realty</Typography>
        <Box height="10px"></Box>
        <p
          style={{
            maxWidth: "600px",
            fontSize: "1.3rem",
          }}
        >
          Get in contact with us, and find your dream home today!
        </p>
      </Backdrop>
    </Paper>
  );
}
