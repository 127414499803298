import { MenuItem, OutlinedInput, Select } from "@mui/material";
import uuid from "react-uuid";
import { palmBeachCities } from "../constants";
import { SearchFieldKeys } from "../enums/SearchFieldKeys";
import { SelectProps } from "../interfaces/SelectProps";

export const CitiesSelectInput: React.FC<SelectProps> = ({
  value,
  onChange,
}) => {
  return (
    <Select
      sx={{ width: "100%" }}
      multiple
      displayEmpty
      value={value}
      onChange={onChange}
      input={<OutlinedInput />}
      renderValue={(selected: string[]) => {
        if (selected.length === 0) {
          return "Cities";
        }

        return selected.join(", ");
      }}
      inputProps={{ "aria-label": "Cities" }}
    >
      <MenuItem disabled value="">
        Cities
      </MenuItem>
      <MenuItem key={uuid()} value={SearchFieldKeys.Any}>
        Any
      </MenuItem>
      {palmBeachCities.map((city: string, index: number) => {
        return (
          <MenuItem key={index} value={city}>
            {city}
          </MenuItem>
        );
      })}
    </Select>
  );
};
