import { Avatar, Box, Grid, Typography } from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import RoundedContainer from "./RoundedContainer";
import { Testimonial } from "../interfaces/Testimonial";

export default function TestimonialCard(props: any) {
  const testimonial = props.testimonial as Testimonial;
  return (
    <RoundedContainer backgroundColor="rgb(237, 249, 255)">
      <FormatQuoteIcon sx={{ fontSize: "50px" }} />
      <Box height="10px"></Box>
      <Typography variant="body1">{testimonial.body}</Typography>
      <Box height="15px"></Box>
      <Grid container direction="row" columnSpacing="30px">
        <Grid item xs={4}>
          <Avatar
            sx={{ width: 70, height: 70 }}
            alt={testimonial.name}
            src={testimonial.imageUrl}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography sx={{ fontWeight: "bold" }} variant="body1">
            {testimonial.name}
          </Typography>
          <Typography variant="body1">
            {testimonial.title},
            <br />
            {testimonial.company}
          </Typography>
        </Grid>
      </Grid>
    </RoundedContainer>
  );
}
