import { MenuItem, OutlinedInput, Select } from "@mui/material";
import React from "react";
import uuid from "react-uuid";
import { SearchFieldKeys } from "../enums/SearchFieldKeys";
import { SelectProps } from "../interfaces/SelectProps";

export const BedroomsSelectInput: React.FC<SelectProps> = ({
  value,
  onChange,
}) => {
  return (
    <Select
      sx={{ width: "100%" }}
      displayEmpty
      value={value}
      onChange={onChange}
      input={<OutlinedInput />}
      renderValue={(selected: string[]) => {
        if (selected.length === 0) {
          return "Bedrooms";
        }

        return selected.join(", ");
      }}
      inputProps={{ "aria-label": "Bedrooms" }}
    >
      <MenuItem disabled value="">
        Bedrooms
      </MenuItem>
      <MenuItem key={uuid()} value={SearchFieldKeys.Any}>
        Any
      </MenuItem>
      <MenuItem key={uuid()} value="1">
        1
      </MenuItem>
      <MenuItem key={uuid()} value="2">
        2
      </MenuItem>
      <MenuItem key={uuid()} value="3+">
        3+
      </MenuItem>
    </Select>
  );
};
