import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTrestleFilteredListings } from "../api/TrestleApiClient";
import {
  containerBorderRadius,
  listingFields,
  sectionSpacing,
} from "../constants";
import { EventType } from "../enums/EventType";
import { Listing } from "../interfaces/Listing";
import { subscribe } from "../utils/Events";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";
import ErrorBoundary from "./ErrorBoundary";
import { ImageListingCard } from "./ImageListingCard";
import SectionTitle from "./SectionTitle";

export default function NewMarketProperties() {
  const [listings, setListings] = useState<Listing[]>([]);
  const dummyLoadingListings = Array.from({ length: 9 }, (_, i) => {
    return (
      <Grid key={i} item xs={12} md={4} lg={4}>
        <Skeleton
          sx={{ height: 300, borderRadius: containerBorderRadius }}
          animation="wave"
          variant="rectangular"
        />
      </Grid>
    );
  });

  const getListingsAsync = async () => {
    const hasToken =
      localStorage.getItem(LocalStorageKeys.trestleToken) !== null;

    if (hasToken) {
      const listings = await getTrestleFilteredListings({
        top: "9",
        select: listingFields.join(","),
        filter:
          "(StandardStatus eq 'Active') and (PropertyType eq 'Residential')",
        expand: "Media($select=MediaURL;$orderby=Order)",
      });
      setListings(listings ?? []);
    } else {
      subscribe(EventType.TrestleTokenFetched, async () => {
        const listings = await getTrestleFilteredListings({
          top: "9",
          select: listingFields.join(","),
          filter:
            "(StandardStatus eq 'Active') and (PropertyType eq 'Residential')",
          expand: "Media($select=MediaURL;$orderby=Order)",
        });
        setListings(listings ?? []);
      });
    }
  };

  useEffect(() => {
    getListingsAsync();
  }, []);

  const navigate = useNavigate();

  return (
    <Container
      maxWidth="lg"
      sx={{
        position: "relative",
      }}
    >
      <Box sx={{ height: sectionSpacing }}></Box>
      <Box>
        <SectionTitle
          title="Hot New Listings"
          subtitle="Check out these new listings on the market!"
        />
        <Box height="30px"></Box>
        <ErrorBoundary
          errorUi={
            <Typography textAlign={"center"} color="error">
              Error: Could not fetch listings.
            </Typography>
          }
        >
          <Grid
            container
            direction="row"
            rowSpacing={{ xs: 3, sm: 2, md: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {listings.length === 0 && dummyLoadingListings}
            {listings.length > 0 &&
              listings.map((listing: Listing) => (
                <Grid key={listing.ListingKey} item xs={12} md={4} lg={4}>
                  <span
                    onClick={async () => {
                      navigate(`/listing/${listing.ListingKey}`, {
                        state: { listing: listing },
                      });
                    }}
                  >
                    <ImageListingCard listing={listing}></ImageListingCard>
                  </span>
                </Grid>
              ))}
          </Grid>
        </ErrorBoundary>

        <Box height="70px"></Box>
      </Box>
    </Container>
  );
}
