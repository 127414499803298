import Box from "@mui/material/Box";
import { Grid, Pagination } from "@mui/material";
import { useState } from "react";
import { Listing } from "../interfaces/Listing";
import { ListingCard } from "./ListingCard";

export const PaginationListingsGrid: React.FC<{ listings: Listing[] }> = ({
  listings,
}) => {
  const max_per_page = 6;
  const numberOfPages = Math.ceil(listings.length / max_per_page);
  const [page, setPage] = useState<number>(1);
  const [paginatedListings, setPaginatedListings] = useState<Listing[]>(
    listings.slice(0, max_per_page)
  );
  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPaginatedListings(
      listings.slice(
        page === 1 ? 0 : max_per_page * (page - 1),
        page * max_per_page
      )
    );
    setPage(page);
  };

  return (
    <>
      {paginatedListings.length > 0 && (
        <>
          <Grid
            container
            rowSpacing={"20px"}
            columnSpacing={"20px"}
            direction="row"
          >
            {paginatedListings.map((listing: Listing) => {
              return (
                <Grid key={listing.ListingKey} item xs={12} md={6}>
                  <ListingCard listing={listing} />
                </Grid>
              );
            })}
          </Grid>
          <Box height="45px"></Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <Pagination
                variant="outlined"
                shape="rounded"
                count={numberOfPages}
                page={page}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Box height="45px"></Box>
        </>
      )}
    </>
  );
};
