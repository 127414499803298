export const handleSharing = async (title: string, text: string) => {
  const canonical: HTMLLinkElement | null = document.querySelector(
    "link[rel=canonical]"
  );
  const url = canonical ? canonical.href : document.location.href;
  const shareDetails = { url, title, text };

  if (navigator.share) {
    try {
      await navigator
        .share(shareDetails)
        .then(() =>
          console.log("Hooray! Your content was shared to tha world")
        );
    } catch (error) {
      console.error(error);
    }
  } else {
    // fallback code
    console.log(
      "Web share is currently not supported on this browser. Please provide a callback"
    );
  }
};
