import { useLocation, useParams } from "react-router-dom";
import Footer from "../components/Footer";

import { ResponsiveAppBar } from "../components/ResponsiveAppBar";
import { sectionBackgroundColor, sectionSpacing } from "../constants";
import { Listing } from "../interfaces/Listing";

import ErrorBoundary from "../components/ErrorBoundary";
import { useEffect, useState } from "react";
import { getTrestleFilteredListings } from "../api/TrestleApiClient";
import { ListingDetailLoaded } from "../components/ListingDetailLoaded";
import { Box } from "@mui/material";
import ListingDetailLoading from "../components/ListingDetailLoading";

export const ListingDetail = () => {
  const location = useLocation();
  const { id: listingKey } = useParams();
  const [listing, setListing] = useState<Listing | null>(null);

  useEffect(() => {
    if (location.state !== null) {
      const stateListing = location.state.listing as Listing;
      setListing(stateListing);
    } else {
      getTrestleFilteredListings({
        expand: "Media($select=MediaURL;$orderby=Order)",
        filter: `ListingKey eq '${listingKey}'`,
      }).then((listings: Listing[] | null) => {
        if (listings !== null) {
          setListing(listings[0]);
          console.log(listings);
        }
      });
    }

    document.title = listing?.UnparsedAddress ?? "Listing";
  }, [listing?.UnparsedAddress, listingKey, location.state]);

  return (
    <ErrorBoundary>
      <Box
        sx={{
          backgroundColor: {
            xs: "#fff",
            sm: sectionBackgroundColor,
            md: sectionBackgroundColor,
          },
        }}
      >
        <ResponsiveAppBar position={{ xs: "static" }} />
        <Box height={{ xs: "0", md: sectionSpacing }}></Box>
        {listing === null && <ListingDetailLoading />}
        {listing !== null && <ListingDetailLoaded listing={listing} />}
        <Box height={"60px"}></Box>
        <Footer />
      </Box>
    </ErrorBoundary>
  );
};
