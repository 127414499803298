import {
  Paper,
  Divider,
  IconButton,
  Autocomplete,
  TextField,
  AutocompleteRenderInputParams,
} from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useState } from "react";
import { getGooglePlaceSuggestions } from "../api/GooglePlacesApiClient";
import { GooglePlace } from "../interfaces/GooglePlace";

export interface CurrentLocation {
  lattitude: number;
  longitude: number;
}

export const GooglePlacesAutocomplete: React.FC<{
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: GooglePlace | null
  ) => void;
  onCurrentLocationSet: (location: CurrentLocation) => void;
}> = ({ onChange, onCurrentLocationSet }) => {
  const [options, setOptions] = useState<GooglePlace[]>([]);

  return (
    <Autocomplete
      onChange={onChange}
      filterOptions={(x) => x}
      sx={{ border: "none" }}
      disablePortal
      id="combo-box-demo"
      placeholder=""
      options={options}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <Paper
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <TextField
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              if (e.target.value !== "") {
                getGooglePlaceSuggestions(e.target.value).then(
                  (places: GooglePlace[] | null) => {
                    setOptions(places ?? []);
                  }
                );
              }
            }}
            sx={{
              "& fieldset": { border: "none" },
            }}
            {...params}
            label="Location"
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            onClick={() => {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  onCurrentLocationSet({
                    lattitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                  });
                },
                (err) => console.error(err)
              );
            }}
            color="primary"
            sx={{ p: "10px" }}
            aria-label="directions"
          >
            <MyLocationIcon />
          </IconButton>
        </Paper>
      )}
    />
  );
};
