import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { detailPages, menuPages } from "./constants";
import { Page } from "./interfaces/Page";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";
import PageNotFound from "./screens/PageNotFound";
import uuid from "react-uuid";
import RootElement from "./components/RootElement";
import ScrollToTop from "./components/ScrollToTop";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <RootElement>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {menuPages.map((page: Page) => {
            return (
              <Route key={page.id} path={page.path} element={page.element} />
            );
          })}
          {detailPages.map((page: Page) => {
            return (
              <Route key={page.id} path={page.path} element={page.element} />
            );
          })}
          <Route key={uuid()} path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </RootElement>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
