import {
  Box,
  Button,
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { containerBorderRadius } from "../constants";
import { BathroomsSelectInput } from "./BathroomsSelectInput";
import { BedroomsSelectInput } from "./BedroomsSelectInput";
import { CitiesSelectInput } from "./CitiesSelectInput";
import { MaxResidentialPriceSelectInput } from "./MaxResidentialPriceSelectInput";
import { StatusSelectInput } from "./StatusSelectInput";
import { useNavigate } from "react-router-dom";
import { MaxResidentialLeasePriceSelectInput } from "./MaxResidentialLeasePriceSelectInput";
import { SearchFieldKeys } from "../enums/SearchFieldKeys";

export default function SearchListingsQuickForm(props: any) {
  const [status, setStatus] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [bedrooms, setBedrooms] = useState<string[]>([]);
  const [bathrooms, setBathrooms] = useState<string[]>([]);
  const [maxPrice, setMaxPrice] = useState<string[]>([]);
  const [rentMaxPrice, setRentMaxPrice] = useState<string[]>([]);

  const navigate = useNavigate();

  const handleStatusChanged = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const allStatusIndex = value.indexOf(SearchFieldKeys.Any);
    if (
      typeof value === "object" &&
      allStatusIndex === value.length - 1 &&
      value.length > 1
    ) {
      setStatus([value[allStatusIndex]]);
    } else if (
      typeof value === "object" &&
      allStatusIndex !== -1 &&
      value.length > 1
    ) {
      value.splice(allStatusIndex, 1);

      setStatus(value);
    } else {
      setStatus(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handleCitiesChanged = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const anyIndex = value.indexOf(SearchFieldKeys.Any);
    if (
      typeof value === "object" &&
      anyIndex === value.length - 1 &&
      value.length > 1
    ) {
      setCities([value[anyIndex]]);
    } else if (
      typeof value === "object" &&
      anyIndex !== -1 &&
      value.length > 1
    ) {
      value.splice(anyIndex, 1);

      setCities(value);
    } else {
      setCities(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handleBedroomsChanged = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setBedrooms(typeof value === "string" ? value.split(",") : value);
  };

  const handleBathroomsChanged = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setBathrooms(typeof value === "string" ? value.split(",") : value);
  };

  const handleMaxPriceChanged = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setMaxPrice(typeof value === "string" ? value.split(",") : value);
  };

  const handleRentMaxPriceChanged = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setRentMaxPrice(typeof value === "string" ? value.split(",") : value);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const params = new URLSearchParams({
      status: status.length > 0 ? status.join(",") : SearchFieldKeys.ForSale,
      cities: cities.length > 0 ? cities.join(",") : SearchFieldKeys.Any,
      bedrooms: bedrooms.length > 0 ? bedrooms.join("") : SearchFieldKeys.Any,
      bathrooms:
        bathrooms.length > 0 ? bathrooms.join("") : SearchFieldKeys.Any,
      maxPrice:
        maxPrice.length > 0 && status.toString() !== SearchFieldKeys.ForRent
          ? maxPrice.join("")
          : SearchFieldKeys.Any,
      rentMaxPrice:
        rentMaxPrice.length > 0 && status.toString() !== SearchFieldKeys.ForSale
          ? rentMaxPrice.join("")
          : SearchFieldKeys.Any,
    });
    const path = `/search-listings?${params}`;
    navigate(path);
  };

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "20px",
        borderRadius: containerBorderRadius,
        boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.1)",
        ...props.style,
      }}
    >
      <Typography variant="h2">Search Listings</Typography>
      <Box height="8px"></Box>
      <Typography>
        Search listings that are for sale or for rent in Palm Beach, Broward,
        and St. Lucie County.
      </Typography>
      <Box height="20px"></Box>

      <Grid container rowSpacing={"20px"} columnSpacing={"20px"}>
        <Grid item xs={12} md={2}>
          <StatusSelectInput value={status} onChange={handleStatusChanged} />
        </Grid>
        <Grid item xs={12} md={2}>
          <CitiesSelectInput value={cities} onChange={handleCitiesChanged} />
        </Grid>
        <Grid item xs={12} md={2}>
          <BedroomsSelectInput
            value={bedrooms}
            onChange={handleBedroomsChanged}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <BathroomsSelectInput
            value={bathrooms}
            onChange={handleBathroomsChanged}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          {(status.includes(SearchFieldKeys.ForSale) ||
            status.length === 0) && (
            <MaxResidentialPriceSelectInput
              value={maxPrice}
              onChange={handleMaxPriceChanged}
            />
          )}
          {status.includes(SearchFieldKeys.ForRent) && (
            <MaxResidentialLeasePriceSelectInput
              value={rentMaxPrice}
              onChange={handleRentMaxPriceChanged}
            />
          )}
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            onClick={handleClick}
            type="submit"
            color="secondary"
            fullWidth
            size="large"
            variant="contained"
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
