import { Divider, Stack, Typography } from "@mui/material";
import { Listing } from "../interfaces/Listing";
import RoundedContainer from "./RoundedContainer";

export default function ListingDescription(props: any) {
  const listing = props.listing as Listing;
  return (
    <RoundedContainer>
      <Stack spacing="20px">
        <Typography
          fontWeight="400"
          fontSize={{ xs: "1.5rem", sm: "1rem" }}
          variant="body1"
        >
          Description
        </Typography>
        <Divider sx={{ display: { xs: "none", sm: "block" } }} />
        <Typography>{listing.PublicRemarks}</Typography>
      </Stack>
    </RoundedContainer>
  );
}
