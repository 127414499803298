import { Typography, Grid, Divider, Box } from "@mui/material";

export default function TableRowWithBottomDivider(props: any) {
  return (
    <>
      <Grid container direction="row">
        <Grid item xs={6}>
          <Typography sx={{ wordWrap: "break-word" }} fontWeight="bold">
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{ wordWrap: "break-word" }}
            textOverflow="ellipsis"
            textAlign="right"
          >
            {props.value}
          </Typography>
        </Grid>
      </Grid>
      <Box height="8px"></Box>
      <Divider />
      <Box height="8px"></Box>
    </>
  );
}
