import {
  Box,
  Grid,
  Stack,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@mui/material";
import React from "react";
import TourIcon from "@mui/icons-material/Tour";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import RoundedContainer from "./RoundedContainer";
import InquiryForm from "./InquiryForm";
import ScheduleTourForm from "./ScheduleTourForm";
import { Listing } from "../interfaces/Listing";
import { containerBorderRadius } from "../constants";
import PersonIcon from "@mui/icons-material/Person";
import AlanMashraghi from "../assets/images/alanMashraghi.jpg";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `graystone-tab-${index}`,
    "aria-controls": `graystone-tabpanel-${index}`,
  };
}

export const ListingsRightSidePanel: React.FC<{
  listing: Listing;
  sx?: SxProps<Theme> | undefined;
}> = ({ listing, sx }) => {
  const [value, setValue] = useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={sx}>
      <RoundedContainer padding={"20px 10px"}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Listing Tour And Info"
          variant="fullWidth"
        >
          <Tab icon={<TourIcon />} {...a11yProps(0)} label="SCHEDULE TOUR" />
          <Tab icon={<InfoIcon />} {...a11yProps(1)} label="GET INFO" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <ScheduleTourForm listing={listing} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid
            alignItems={"center"}
            container
            columnSpacing={"20px"}
            direction="row"
          >
            <Grid xs={6} item>
              <img
                style={{
                  borderRadius: containerBorderRadius,
                  maxWidth: "150px",
                }}
                alt="Alan Mashraghi"
                width="100%"
                height="auto"
                src={AlanMashraghi}
              />
            </Grid>
            <Grid xs={6} item>
              <Stack alignItems="center">
                <PersonIcon sx={{ fontSize: "2rem", textAlign: "center" }} />
                <Typography>Alan Mashraghi</Typography>
              </Stack>
            </Grid>
          </Grid>
          <Box height="20px"></Box>
          <Typography variant="body1">
            Contact us to get some more information about this property.
          </Typography>
          <InquiryForm padding="0" />
        </TabPanel>
      </RoundedContainer>
    </Box>
  );
};
