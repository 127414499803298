import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, ButtonBase, Divider, Grid, IconButton } from "@mui/material";
import BedIcon from "@mui/icons-material/Bed";
import ShowerIcon from "@mui/icons-material/Shower";
import { Listing } from "../interfaces/Listing";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import IosShareIcon from "@mui/icons-material/IosShare";
import NoImagePlaceHolder from "../assets/images/noImagePlaceholder.png";
import { USDCurrencyFormatter } from "../utils/NumberFormatter";
import { useNavigate } from "react-router-dom";
import { ImagePlaceHolder } from "./ImagePlaceHolder";
import { ListingTag } from "./ListingTag";
import { SearchFieldKeys } from "../enums/SearchFieldKeys";
import { handleSharing } from "../utils/WebShareHelper";

export const ListingCard: React.FC<{ listing: Listing }> = ({ listing }) => {
  const iconMarginRight = "4px";
  const navigate = useNavigate();

  return (
    <Card sx={{ width: "100%", position: "relative" }}>
      {listing.Media !== null && listing.Media !== undefined && (
        <ImagePlaceHolder
          style={{ height: "200px", width: "100%", objectFit: "cover" }}
          alt={listing.UnparsedAddress ?? ""}
          src={listing.Media[0].MediaURL}
          widthLoading={"100%"}
          heightLoading={"200px"}
        />
      )}

      {listing.Media === null && (
        <CardMedia
          height="200px"
          component="img"
          image={NoImagePlaceHolder}
          alt={listing.UnparsedAddress}
        />
      )}

      <ButtonBase
        onClick={async () => {
          navigate(`/listing/${listing.ListingKey}`, {
            state: { listing: listing },
          });
        }}
        style={{
          position: "absolute",
          top: "0px",
          height: "200px",
          left: "0",
          right: "0",
          color: "white",
          backgroundImage:
            "linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,.75) 100%)",
        }}
      >
        <span
          style={{
            position: "absolute",
            bottom: "17px",
            left: "17px",
            fontSize: "1.3rem",
          }}
        >
          {USDCurrencyFormatter.format(listing.ListPrice)}
        </span>
        <ListingTag
          title={listing.StandardStatus?.toUpperCase() ?? "None"}
          backgroundColor="rgba(0,0,0,.65)"
          sx={{
            position: "absolute",
            top: "17px",
            right: "17px",
            fontSize: "13px",
          }}
        ></ListingTag>
        {listing.PropertyType === SearchFieldKeys.ResidentialLease && (
          <ListingTag
            backgroundColor="primary.main"
            sx={{ position: "absolute", top: "17px", right: "90px" }}
            title={"For Rent"}
          />
        )}
        {listing.PropertyType === SearchFieldKeys.Residential && (
          <ListingTag
            backgroundColor="primary.main"
            sx={{ position: "absolute", top: "17px", right: "90px" }}
            title={"For Sale"}
          />
        )}
      </ButtonBase>
      <IconButton
        sx={{ position: "absolute", top: "17px", left: "17px" }}
        aria-label="share"
        onClick={(event: React.MouseEvent) => {
          handleSharing(
            listing.UnparsedAddress ?? "None",
            listing.PublicRemarks ?? "None"
          );
        }}
      >
        <IosShareIcon sx={{ color: "white" }} />
      </IconButton>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" color="secondary">
          <span
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={() => {
              navigate(`/listing/${listing.ListingKey}`, {
                state: { listing: listing },
              });
            }}
          >
            {listing.UnparsedAddress}
          </span>
        </Typography>
        <Typography
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          variant="body1"
        >
          {listing.PublicRemarks}
        </Typography>
        <Box height="16px"></Box>
        <Grid container direction="row">
          <Grid item xs={3}>
            <BedIcon sx={{ marginRight: iconMarginRight }} />
            <span>{listing.BedroomsTotal}</span>
          </Grid>
          <Grid item xs={3}>
            <ShowerIcon sx={{ marginRight: iconMarginRight }} />
            <span>{listing.BathroomsFull}</span>
            {/* TODO: Add half bathrooms here */}
          </Grid>
          <Grid item xs={3}>
            <DirectionsCarIcon sx={{ marginRight: iconMarginRight }} />
            <span>{listing.CoveredSpaces}</span>
          </Grid>
          <Grid item xs={3}>
            <SquareFootIcon sx={{ marginRight: iconMarginRight }} />
            <span>{listing.LivingArea}</span>
          </Grid>
        </Grid>
        <Box height="35px"></Box>
        <Divider />
        <Box height="35px"></Box>
        <Grid alignItems={"center"} direction={"row"} container>
          <Grid item xs={6}>
            <Typography variant="h4">
              {listing.PropertyType?.toUpperCase()}
            </Typography>
          </Grid>
          <Grid sx={{ textAlign: "right" }} item xs={6}>
            <span style={{ display: "inline-block" }}>{listing.ListingId}</span>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
