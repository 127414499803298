import { Box, Grid, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
import { GoogleMap } from "../components/GoogleMap";
import { ResponsiveAppBar } from "../components/ResponsiveAppBar";
import { SearchListingsForm } from "../components/SearchListingsForm";
import { Listing } from "../interfaces/Listing";
import { SearchListingsFormData } from "../interfaces/SearchListingsFormData";
import { PaginationListingsGrid } from "../components/PaginationListingsGrid";
import { useLocation } from "react-router-dom";
import { getTrestleFilteredListings } from "../api/TrestleApiClient";
import { listingFields } from "../constants";
import { EventType } from "../enums/EventType";
import { subscribe } from "../utils/Events";
import { LocalStorageKeys } from "../utils/LocalStorageKeys";
import {
  getLocationFilterString,
  getMaxPriceResidentialFilterString,
  getMaxPriceResidentialLeaseFilterString,
  getSearchBathroomsFilterString,
  getSearchBedroomsFilterString,
  getSearchCitiesFilterString,
  getSearchSqftFilterString,
  getSearchStatusFilterString,
  getSearchZipCodeFilterString,
} from "../utils/SearchListingsHelper";
import { SearchFieldKeys } from "../enums/SearchFieldKeys";
import LoadingListingCard from "../components/LoadingListingCard";
import Footer from "../components/Footer";

export const SearchListings = () => {
  useEffect(() => {
    document.title = "Search Listings";
  }, []);

  const [listings, setListings] = useState<Listing[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [empty, setEmpty] = useState<boolean>(false);
  const location = useLocation();

  const dummyLoadingListings = Array.from({ length: 4 }, (_, i) => {
    return (
      <Grid xs={12} md={6} item key={i}>
        <LoadingListingCard />
      </Grid>
    );
  });

  const handleSubmit = (data: SearchListingsFormData) => {
    getSearchListingsAsync(data);
  };

  // Gets the listings for when the search form is submitted on the search listings page
  const getSearchListingsAsync = async (data: SearchListingsFormData) => {
    window.history.pushState(
      {},
      document.title,
      `${window.location.origin}/search-listings`
    );

    setLoading(true);
    const hasToken =
      localStorage.getItem(LocalStorageKeys.trestleToken) !== null;
    const useFilterStringAny: boolean =
      data.status?.toString() === SearchFieldKeys.Any &&
      data.cities?.toString() === SearchFieldKeys.Any &&
      data.bedrooms?.toString() === SearchFieldKeys.Any &&
      data.bathrooms?.toString() === SearchFieldKeys.Any &&
      data.priceRange.toString() === SearchFieldKeys.Any &&
      data.rentPriceRange.toString() === SearchFieldKeys.Any &&
      data.zipCode === SearchFieldKeys.Any;

    /// The filter string when all fields are any
    const filterStringAny =
      "(StandardStatus eq 'Active') and (PropertyType eq 'Residential') and (ListPrice) le 600000";
    // The filter string when one or more fields are NOT "Any"
    const priceFilterString =
      data.status.toString() === SearchFieldKeys.ForSale ||
      data.status.toString() === SearchFieldKeys.Any
        ? getMaxPriceResidentialFilterString(data.priceRange)
        : getMaxPriceResidentialLeaseFilterString(data.rentPriceRange);

    const locationFilterString =
      data.location !== null ? getLocationFilterString(data.location) : "";

    const filterString = `(StandardStatus eq 'Active')${getSearchStatusFilterString(
      data.status
    )}${getSearchCitiesFilterString(
      data.cities
    )}${getSearchBathroomsFilterString(
      data.bathrooms
    )}${getSearchBedroomsFilterString(
      data.bedrooms
    )}${priceFilterString}${getSearchSqftFilterString(
      data.sqft[0].split("-")
    )}${locationFilterString}${getSearchZipCodeFilterString(data.zipCode)}`;
    console.log(filterString);
    if (hasToken) {
      const listings = await getTrestleFilteredListings({
        top: "50",
        select: listingFields.join(","),
        filter: useFilterStringAny ? filterStringAny : filterString,
        expand: "Media($select=MediaURL;$orderby=Order)",
      });

      if (listings === null || listings.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }

      setListings(listings ?? []);
      setLoading(false);
    } else {
      subscribe(EventType.TrestleTokenFetched, async () => {
        const listings = await getTrestleFilteredListings({
          top: "9",
          select: listingFields.join(","),
          filter: useFilterStringAny ? filterStringAny : filterString,
          expand: "Media($select=MediaURL;$top=1;$orderby=Order)",
        });

        if (listings === null || listings.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
        }

        setListings(listings ?? []);
        setLoading(false);
      });
    }
  };

  // Gets the listings for when the quick search form is submitted
  const getQuickSearchListingsAsync = async (
    status: string[],
    cities: string[],
    bedrooms: string[],
    bathrooms: string[],
    maxPrice: string[],
    rentMaxPrice: string[]
  ) => {
    setLoading(true);
    const hasToken =
      localStorage.getItem(LocalStorageKeys.trestleToken) !== null;

    const useFilterStringAny: boolean =
      status?.toString() === SearchFieldKeys.Any &&
      cities?.toString() === SearchFieldKeys.Any &&
      bedrooms?.toString() === SearchFieldKeys.Any &&
      bathrooms?.toString() === SearchFieldKeys.Any &&
      maxPrice.toString() === SearchFieldKeys.Any &&
      rentMaxPrice.toString() === SearchFieldKeys.Any;

    /// The filter string when all fields are any
    const filterStringAny =
      "(StandardStatus eq 'Active') and (PropertyType eq 'Residential') and (ListPrice) le 600000";

    // The filter string when one or more fields are NOT "Any"
    const maxPriceFilterString =
      status.toString() === SearchFieldKeys.ForSale
        ? getMaxPriceResidentialFilterString(maxPrice)
        : getMaxPriceResidentialLeaseFilterString(rentMaxPrice);

    const filterString = `(StandardStatus eq 'Active')${getSearchStatusFilterString(
      status
    )}${getSearchCitiesFilterString(cities)}${getSearchBathroomsFilterString(
      bathrooms
    )}${getSearchBedroomsFilterString(bedrooms)}${maxPriceFilterString}`;

    console.log(filterString);
    if (hasToken) {
      const listings = await getTrestleFilteredListings({
        top: "50",
        select: listingFields.join(","),
        filter: useFilterStringAny ? filterStringAny : filterString,
        expand: "Media($select=MediaURL;$orderby=Order)",
      });

      if (listings === null || listings.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }

      setListings(listings ?? []);
      setLoading(false);
    } else {
      subscribe(EventType.TrestleTokenFetched, async () => {
        const listings = await getTrestleFilteredListings({
          top: "9",
          select: listingFields.join(","),
          filter: useFilterStringAny ? filterStringAny : filterString,
          expand: "Media($select=MediaURL;$top=1;$orderby=Order)",
        });

        if (listings === null || listings.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
        }

        setListings(listings ?? []);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const status = url.searchParams.get("status");
    const cities = url.searchParams.get("cities");
    const bedrooms = url.searchParams.get("bedrooms");
    const bathrooms = url.searchParams.get("bathrooms");
    const maxPrice = url.searchParams.get("maxPrice");
    const rentMaxPrice = url.searchParams.get("rentMaxPrice");

    if (
      status !== null &&
      cities !== null &&
      bedrooms !== null &&
      bathrooms !== null &&
      maxPrice !== null &&
      rentMaxPrice !== null
    ) {
      getQuickSearchListingsAsync(
        status.split(",") ?? [SearchFieldKeys.Any],
        cities.split(",") ?? [SearchFieldKeys.Any],
        bedrooms.split(",") ?? [SearchFieldKeys.Any],
        bathrooms.split(",") ?? [SearchFieldKeys.Any],
        maxPrice.split(",") ?? [SearchFieldKeys.Any],
        rentMaxPrice.split(",") ?? [SearchFieldKeys.Any]
      );
    }
  }, [location]);

  return (
    <ErrorBoundary>
      <ResponsiveAppBar position={{ xs: "static" }} />
      <Grid container columnSpacing={"20px"} direction="row">
        <Grid item xs={12} md={5}>
          <GoogleMap listings={listings} />
        </Grid>
        <Grid item xs={12} md={7}>
          <Box
            sx={{ maxHeight: { xs: "100%", md: "100vh" }, overflow: "auto" }}
          >
            <Container>
              <Box height="20px"></Box>
              <SearchListingsForm onSubmit={handleSubmit} />
              <Box height="20px"></Box>
              {loading && (
                <Grid
                  rowSpacing={"20px"}
                  columnSpacing={"20px"}
                  direction={"row"}
                  container
                >
                  {dummyLoadingListings}
                </Grid>
              )}
              {listings.length > 0 && !loading && !empty && (
                <>
                  <Typography>{listings!.length} Results Found</Typography>
                  <Box height="20px"></Box>
                  <PaginationListingsGrid listings={listings} />
                </>
              )}
              {empty && <Typography>No Results Found</Typography>}
            </Container>
          </Box>
        </Grid>
      </Grid>
      <Box display={{ xs: "block", md: "none" }} height={"100px"}></Box>
      <Footer />
    </ErrorBoundary>
  );
};
