import axios from "axios";
import { timeout } from "../constants";
import { Urls } from "../utils/Urls";

// Gets the captcha score for the user
export const getCaptchaScore = async (token: string): Promise<boolean> => {
  const url = Urls.googleCaptcha(token);
  try {
    const response = await axios.post(
      url.toString(),
      { token: token },
      { timeout: timeout }
    );
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    console.error(error);
  }

  return false;
};
