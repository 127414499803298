import { Box, Typography, Divider, Grid, Stack } from "@mui/material";
import { Center } from "./Center";

export default function AboutUsIntro() {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        padding: "48px",
        color: "#fff",
      }}
    >
      <Grid container direction="row" columnSpacing="100px" rowSpacing="30px">
        <Grid xs={12} md={6} item>
          <Center>
            <Box>
              <Typography textAlign={"center"} fontWeight="bold" variant="h3">
                Graystone
              </Typography>
              <Typography textAlign={"center"} variant="h2">
                Realty
              </Typography>
            </Box>
          </Center>
        </Grid>
        <Divider
          variant="middle"
          orientation="vertical"
          flexItem
          sx={{ mr: { xs: "0px", md: "-1px" }, borderColor: "#fff" }}
        />
        <Grid xs={12} md={6} item>
          <Stack spacing={"20px"}>
            <Typography>
              Welcome to Graystone Realty, a Florida-based real estate brokerage
              dedicated to providing exceptional service to our clients. At
              Graystone Realty, we understand that buying or selling a home can
              be one of the most significant financial transactions of your
              life. That's why we make it our mission to guide you through the
              process with care, professionalism, and expertise.
            </Typography>
            <Typography>
              Our team of experienced agents has a deep understanding of the
              Florida real estate market and can help you find the perfect
              property to suit your needs. Whether you're looking for a
              beachfront condo, a family-friendly suburban home, or a luxurious
              estate, we have the expertise to help you navigate the local
              market.
            </Typography>
            <Typography>
              At Graystone Realty, we believe that communication is key, and we
              strive to keep our clients informed throughout every step of the
              process. From the initial consultation to the final closing, we
              are committed to providing personalized attention and a high level
              of service.
            </Typography>
            <Typography>
              We also offer a range of resources and tools to help our clients
              make informed decisions about buying or selling their property.
              Our website features up-to-date listings, market reports, and
              educational resources to help you stay informed about the latest
              trends and developments in the Florida real estate market.
            </Typography>
            <Typography>
              Whether you're a first-time homebuyer or an experienced investor,
              Graystone Realty is here to help you achieve your real estate
              goals. Contact us today to learn more about how we can help you
              navigate the Florida real estate market with confidence and ease.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
