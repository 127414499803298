import { Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import { ListingActionIconButtons } from "./ListingActionIconButtons";
import { ListingTag } from "./ListingTag";
import { Listing } from "../interfaces/Listing";
import { USDCurrencyFormatter } from "../utils/NumberFormatter";
import { SearchFieldKeys } from "../enums/SearchFieldKeys";

export const ListingDetailHeaderInfo: React.FC<{
  listing: Listing;
  sx?: SxProps<Theme> | undefined;
}> = ({ listing, sx }) => {
  return (
    <Grid
      sx={sx}
      rowSpacing="10px"
      direction="row"
      container
      justifyContent="space-between"
    >
      <Grid xs={12} md={6} item>
        <Typography variant="h1" sx={{ display: { xs: "none", md: "block" } }}>
          {listing.UnparsedAddress}
        </Typography>
        <ListingActionIconButtons
          listing={listing}
          sx={{ display: { xs: "flex", md: "none" } }}
        />
        <Box height="12px"></Box>
        <ListingTag
          backgroundColor={
            listing.StandardStatus === "Active"
              ? "rgb(119, 199, 32)"
              : undefined
          }
          title={listing.StandardStatus ?? "None"}
        />
        {listing.PropertyType === SearchFieldKeys.ResidentialLease && (
          <ListingTag
            backgroundColor="primary.main"
            sx={{ marginLeft: "8px" }}
            title={"For Rent"}
          />
        )}
        {listing.PropertyType === SearchFieldKeys.Residential && (
          <ListingTag
            sx={{ marginLeft: "8px" }}
            backgroundColor="primary.main"
            title={"For Sale"}
          />
        )}
        <Box height="12px"></Box>
        <Grid
          sx={{ fontSize: "1.1rem" }}
          columnSpacing="8px"
          container
          direction="row"
          alignItems={"center"}
        >
          <Grid item>
            <PlaceIcon />
          </Grid>
          <Grid item>
            <Typography variant="body1">{`${[
              listing.UnparsedAddress,
              listing.PostalCity,
              listing.PostalCode,
            ].join(", ")}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} md={6} item>
        <Typography textAlign={{ xs: "left", md: "right" }} variant="h1">
          {USDCurrencyFormatter.format(listing.ListPrice)}
        </Typography>
        <Box height="10px"></Box>
        <ListingActionIconButtons
          listing={listing}
          sx={{
            display: { xs: "none", md: "flex", justifyContent: "right" },
          }}
        />
      </Grid>
    </Grid>
  );
};
