import { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 as uuid } from "uuid";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation } from "swiper";
import SwiperCore from "swiper";
import { Stack, SxProps, Theme } from "@mui/material";
import RoundedContainer from "./RoundedContainer";
import { Listing } from "../interfaces/Listing";
import { ImagePlaceHolder } from "./ImagePlaceHolder";

export const ListingImagesSlider: React.FC<{
  listing: Listing;
  sx?: SxProps<Theme> | undefined;
}> = ({ listing, sx }) => {
  const [swiper, setSwiper] = useState<SwiperCore>();

  const slideTo = (index: number) => {
    if (!swiper || swiper.destroyed) {
      return;
    }
    swiper.slideTo(index);
  };

  return (
    <Stack spacing="5px" sx={sx}>
      <Swiper
        cssMode={true}
        style={{ backgroundColor: "white" }}
        onSwiper={setSwiper}
        spaceBetween={10}
        navigation={true}
        modules={[FreeMode, Navigation]}
        className="mySwiper2"
      >
        {listing.Media != null &&
          listing.Media !== undefined &&
          listing.Media.map((media, index: number) => {
            return (
              <SwiperSlide key={uuid()}>
                <ImagePlaceHolder
                  style={{
                    width: "100%",
                    height: "500px",
                    objectFit: "cover",
                  }}
                  alt={`${listing.UnparsedAddress}-${index}`}
                  src={media.MediaURL}
                  widthLoading={"100%"}
                  heightLoading={500}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
      <RoundedContainer padding="10px">
        <Swiper
          spaceBetween={3}
          slidesPerView={4}
          freeMode={true}
          modules={[FreeMode, Navigation]}
        >
          {listing.Media != null &&
            listing.Media !== undefined &&
            listing.Media.map((media, index: number) => {
              return (
                <SwiperSlide key={uuid()}>
                  <ImagePlaceHolder
                    heightLoading={100}
                    widthLoading={200}
                    onClick={() => {
                      slideTo(index);
                    }}
                    style={{
                      height: "100px",
                      width: "100%",
                      maxWidth: "200px",
                      objectFit: "cover",
                    }}
                    alt={`${listing.UnparsedAddress}-${index}`}
                    src={media.MediaURL}
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </RoundedContainer>
    </Stack>
  );
};
