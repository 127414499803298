import { Box, Typography } from "@mui/material";
import Container from "@mui/material/Container";

import { sectionBackgroundColor } from "../constants";
import ErrorBoundary from "./ErrorBoundary";
import { ListingsSlider } from "./ListingsSlider";
import SearchListingsQuickForm from "./SearchListingsQuickForm";
import SectionTitle from "./SectionTitle";

export const FeaturedListingsSection: React.FC<{
  title: string;
  subtitle: string;
}> = ({ title, subtitle }) => {
  return (
    <Box sx={{ backgroundColor: sectionBackgroundColor }}>
      <Container
        maxWidth="lg"
        sx={{
          position: "relative",
        }}
      >
        <SearchListingsQuickForm
          style={{ position: "absolute", top: "-65px", left: 10, right: 10 }}
        />
        <Box sx={{ height: { md: "180px", xs: "550px" } }}></Box>
        <Box>
          <SectionTitle title={title} subtitle={subtitle} />
          <Box height="70px"></Box>
          <ErrorBoundary
            errorUi={
              <Typography textAlign={"center"} color="error">
                Error: Could not fetch listings.
              </Typography>
            }
          >
            <ListingsSlider />
          </ErrorBoundary>

          <Box height="70px"></Box>
        </Box>
      </Container>
    </Box>
  );
};
