import "bootstrap/dist/css/bootstrap.min.css";
import Jumbotron from "../components/Jumbotron";
import { FeaturedListingsSection } from "../components/FeaturedListingsSection";
import InquiryFormSection from "../components/InquiryFormSection";
import NewMarketProperties from "../components/NewMarketProperties";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import { ResponsiveAppBar } from "../components/ResponsiveAppBar";
import MeetAgents from "../components/MeetAgents";
import ErrorBoundary from "../components/ErrorBoundary";
import { useEffect } from "react";

function Home() {
  useEffect(() => {
    document.title = "Home";
  }, []);

  return (
    <ErrorBoundary>
      <ResponsiveAppBar
        backgroundColor={{ md: "transparent" }}
        position={{ xs: "static", md: "absolute" }}
      />
      <Jumbotron />
      <FeaturedListingsSection
        title="Featured Listings"
        subtitle="Get a look at some of our featured listings"
      />
      <InquiryFormSection />
      <NewMarketProperties />
      <MeetAgents />
      <Testimonials />
      <Footer />
    </ErrorBoundary>
  );
}

export default Home;
