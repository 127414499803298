import { useEffect } from "react";
import AboutUsIntro from "../components/AboutUsIntro";
import ErrorBoundary from "../components/ErrorBoundary";
import Footer from "../components/Footer";
import ImageWithTitle from "../components/ImageWithTitle";
import MeetAgents from "../components/MeetAgents";
import { ResponsiveAppBar } from "../components/ResponsiveAppBar";

export default function About() {
  useEffect(() => {
    document.title = "About";
  }, []);

  return (
    <ErrorBoundary>
      <ResponsiveAppBar position={{ xs: "static" }} />
      <ImageWithTitle title="About Us" />
      <AboutUsIntro />
      <MeetAgents />
      <Footer />
    </ErrorBoundary>
  );
}
